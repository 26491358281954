import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import * as S from './styles'

import EditContentDialog from '../EditContentDialog'
import PublishContent from '../../PublishContent'
import { alertActions, contentActions } from '../../../../app/_actions'

const EditContentWrapper = ({
	selectedContentId,
	setSelectedContentId,
	contentModalOpen,
	setContentModalOpen,
	resetContentId,
	onClose = null,
	onUpdate = null,
	skipPublishDialogOnFinish = false
}) => {
	const dispatch = useDispatch()
	// @ts-ignore
	const alert = useSelector((state) => state.alert)

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [publishModalOpen, setPublishModalOpen] = useState(false)
	const [publishConfirmOpen, setPublishConfirmOpen] = useState(false)

	const handleOpenContentModal = useCallback(() => {
		setContentModalOpen(true)
		setPublishModalOpen(false)
	}, [setContentModalOpen])

	const handleOpenPublishModal = useCallback(() => {
		setContentModalOpen(false)
		setPublishModalOpen(true)
	}, [setContentModalOpen])

	const handleDelete = useCallback(() => {
		setDeleteConfirmOpen(true)
	}, [setDeleteConfirmOpen])

	const handleDeleteConfirmClose = useCallback(() => {
		setDeleteConfirmOpen(false)
	}, [setDeleteConfirmOpen])

	const handlePublish = useCallback(() => {
		setPublishConfirmOpen(true)
	}, [setPublishConfirmOpen])

	const handlePublishConfirmClose = useCallback(() => {
		setPublishConfirmOpen(false)
	}, [setPublishConfirmOpen])

	const handleCloseContentModal = useCallback(() => {
		if (skipPublishDialogOnFinish && onClose) {
			onClose(selectedContentId)
		}
		resetContentId()
	}, [resetContentId, onClose, skipPublishDialogOnFinish, selectedContentId])

	const handleClosePublishModal = useCallback(() => {
		dispatch(contentActions.cleanContent())
		resetContentId()
		if (!skipPublishDialogOnFinish && onClose) {
			onClose(selectedContentId)
		}
	}, [
		dispatch,
		resetContentId,
		onClose,
		skipPublishDialogOnFinish,
		selectedContentId
	])

	const handleSnackbarClose = useCallback(() => {
		setTimeout(() => {
			dispatch(alertActions.clear())
		}, 1000)
		setSnackbarOpen(false)
	}, [dispatch, setSnackbarOpen])

	const doPublish = useCallback(() => {
		if (selectedContentId) {
			dispatch(
				contentActions.publishContent(selectedContentId, () => {
					handleClosePublishModal()
					handlePublishConfirmClose()
					if (onUpdate) {
						onUpdate()
					}
				})
			)
		}
	}, [
		selectedContentId,
		dispatch,
		handlePublishConfirmClose,
		handleClosePublishModal,
		onUpdate
	])

	useEffect(() => {
		if (alert.type) {
			setSnackbarOpen(true)
		}
	}, [alert.type])

	useEffect(() => {
		if (contentModalOpen) {
			setPublishModalOpen(false)
		}
	}, [contentModalOpen])

	const doDelete = useCallback(() => {
		handleDeleteConfirmClose()
		if (selectedContentId) {
			dispatch(
				contentActions.deleteContent(selectedContentId, () => {
					handleClosePublishModal()
					if (onUpdate) {
						onUpdate()
					}
				})
			)
		}
	}, [
		selectedContentId,
		dispatch,
		onUpdate,
		handleDeleteConfirmClose,
		handleClosePublishModal
	])

	useEffect(() => {
		if (selectedContentId && !contentModalOpen) {
			handleOpenPublishModal()
		}
	}, [selectedContentId, handleOpenPublishModal, contentModalOpen])

	return (
		<>
			<EditContentDialog
				handleClose={handleCloseContentModal}
				openPublishModal={handleOpenPublishModal}
				setSelectedContentId={setSelectedContentId}
				initialContentId={selectedContentId}
				isModalOpen={contentModalOpen}
				onUpdate={onUpdate}
				skipPublishDialogOnFinish={skipPublishDialogOnFinish}
			/>
			<PublishContent
				// edit={!!contentIdToBeEdited}
				open={publishModalOpen}
				handleClose={handleClosePublishModal}
				startEdit={handleOpenContentModal}
				title="Editar ou enviar conteúdo para avaliação"
				onDelete={handleDelete}
				onSubmitToPublish={handlePublish}
			/>
			<Dialog open={deleteConfirmOpen} onClose={handleDeleteConfirmClose}>
				<DialogTitle>Apagar item</DialogTitle>
				<DialogContent>
					Atenção, você não poderá recuperar este item após apagá-lo!
					Tem certeza que quer apagar?
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={doDelete}
					>
						Sim, apagar!
					</Button>
					<Button onClick={handleDeleteConfirmClose}>Cancelar</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={publishConfirmOpen}
				onClose={handlePublishConfirmClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Confirmação do Termo de publicação do conteúdo
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Declaro que estou enviando esse material e ele está de
						acordo com o{' '}
						<S.StyledLink
							to="/termo-e-condicoes-de-uso"
							target="_blank"
						>
							Termo e condições de uso
						</S.StyledLink>{' '}
						assinado para o portal.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handlePublishConfirmClose}>
						Não concordo
					</Button>
					<Button onClick={doPublish} autoFocus>
						Concordo
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={alert.type !== 'green' ? 'warning' : 'success'}
					variant="filled"
					sx={{ width: '100%' }}
				>
					{alert.message ? alert.message : 'Salvo com sucesso'}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditContentWrapper
