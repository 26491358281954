import React, { useState, useEffect, useCallback } from 'react'
import { Grid, GridColumn, Header, Input } from 'semantic-ui-react'
import debounce from 'lodash.debounce'
import { useDispatch, useSelector } from 'react-redux'
import { orgsActions } from '../../../app/_actions'

import DataTable from '../../DataTable'

import * as S from './styles'

const orgColumns = [{ name: 'name', label: 'Nome', width: '14' }]

const ManageOrgs = () => {
	const [pageOrgs, setPageOrgs] = useState(1)
	// @ts-ignore
	const { orgs, loadingOrgs } = useSelector((state) => state.orgs)
	const PER_PAGE = 12
	const dispatch = useDispatch()

	const getData = useCallback(() => {
		const getOrg = orgsActions.getAllPaginated(pageOrgs, PER_PAGE)
		dispatch(getOrg)
	}, [dispatch, pageOrgs, PER_PAGE])

	useEffect(() => {
		getData()
	}, [getData])

	// eslint-disable-next-line
	const debounceOrgs = useCallback(
		debounce(
			(value) =>
				dispatch(orgsActions.getAllPaginated(1, PER_PAGE, value)),
			500
		),
		[]
	)

	const handleOrgSearch = ({ target }) => {
		debounceOrgs(target.value)
	}

	const deleteOrg = (org) => {
		dispatch(
			orgsActions.delete(org.id, () => {
				getData()
			})
		)
	}

	return (
		<S.Panel celled="internally">
			<Grid.Row>
				<GridColumn>
					<Header>Gerenciar Organizações</Header>
					<Input
						icon="search"
						onChange={handleOrgSearch}
						placeholder="Pesquisar organização..."
					/>
					<DataTable
						prefixKey="org_"
						data={orgs ? orgs.organizations : []}
						totalResults={orgs ? orgs.totalResults : 0}
						loading={loadingOrgs}
						page={pageOrgs}
						setPage={setPageOrgs}
						columns={orgColumns}
						onDelete={deleteOrg}
						deleteTitle="Exclusão da organização"
						deleteMessage="Tem certeza que deseja excluir a organização selecionada?"
					/>
				</GridColumn>
			</Grid.Row>
		</S.Panel>
	)
}

export default ManageOrgs
