import React, { memo } from 'react'
import { InputLabel, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'

import * as S from '../styles'
import { getTmpId, slugify } from '../../../../utils/functions'

const filter = createFilterOptions()

export function AutocompleteEditable({
	fieldKey,
	label,
	placeholder,
	data,
	value,
	disabled = false,
	onChange,
	error = false,
	multiple = true,
	allowCreateRelated = false,
	loading = false
}) {
	const { t } = useTranslation()

	const getOptionLabel = (option) => {
		if (allowCreateRelated) {
			// Value selected with enter, right from the input
			if (typeof option === 'string') {
				return option
			}
			// Criar "xxx" option created dynamically
			if (option.inputValue) {
				return option.inputValue
			}
		}
		// Regular option
		return option.name || data.find((item) => item.id === option.id).name
	}

	const isOptionEqualToValue = (option, value) => option.id === value.id

	const onLocalChange = (_, values) => {
		let valueAr = []
		if (multiple) {
			for (let valueRaw of values) {
				if (typeof valueRaw === 'string') {
					const newValues = valueRaw.split(',')
					for (let newValue of newValues) {
						valueAr.push(newValue.trim())
					}
					continue
				}
				if (valueRaw && valueRaw.inputValue) {
					const newValues = valueRaw.inputValue.split(',')
					for (let newValue of newValues) {
						valueAr.push({ inputValue: newValue.trim() })
					}
					continue
				}
				valueAr.push(valueRaw)
			}
		} else {
			valueAr = [values]
		}
		const newValueAr = []
		if (valueAr) {
			// @ts-ignore
			for (let newValue of valueAr) {
				if (allowCreateRelated) {
					if (typeof newValue === 'string') {
						const tmpId = getTmpId()
						newValue = {
							id: tmpId,
							name: newValue
						}
					}
					if (newValue && newValue.inputValue) {
						const tmpId = getTmpId()
						newValue = {
							id: tmpId,
							name: newValue.inputValue
						}
					}
				}
				newValueAr.push(newValue)
			}
		}
		onChange(
			multiple
				? { target: { name: fieldKey, value: newValueAr } }
				: {
						target: {
							name: fieldKey,
							value: newValueAr[0]
						}
				  }
		)
	}

	const filterOptions = allowCreateRelated
		? (options, params) => {
				if (options.length === 0) {
					return []
				}
				const filtered = filter(options, params)

				const { inputValue } = params
				const inputValueSlug = slugify(inputValue)
				// Suggest the creation of a new value
				const isExisting = options.some(
					(option) =>
						// @ts-ignore
						inputValueSlug === slugify(option.name)
				)
				if (inputValue !== '' && !isExisting && filtered.length === 0) {
					filtered.push({
						inputValue,
						name: `Criar "${inputValue}"`
					})
				}
				return filtered
		  }
		: undefined

	const Option = (props, option) => {
		const { key, ...liProps } = props
		return (
			<li key={key} {...liProps}>
				{option.name}
			</li>
		)
	}

	const Tags = (values, getTagProps) =>
		values.map((value, index) => {
			const { key, ...props } = getTagProps({
				index
			})
			const selectedOption = data.find((option) => option.id === value.id)
			return (
				<S.StyledChip
					key={key}
					label={selectedOption ? selectedOption.name : ''}
					{...props}
				/>
			)
		})

	const Input = (params) => (
		<TextField
			{...params}
			placeholder={placeholder}
			error={error}
			helperText={error ? 'Campo obrigatório' : ''}
		/>
	)

	return (
		<S.InputWrapper>
			<InputLabel>{label}</InputLabel>
			<S.StyledAutocomplete
				multiple={multiple}
				disabled={disabled}
				fullWidth
				value={value || null}
				freeSolo={allowCreateRelated}
				options={data}
				noOptionsText={t('dashboard.no-options', 'Não há opções')}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={isOptionEqualToValue}
				onChange={onLocalChange}
				filterOptions={filterOptions}
				renderOption={Option}
				renderTags={Tags}
				renderInput={Input}
				loading={loading}
				loadingText={t('dashboard.loading', 'Carregando')}
			/>
		</S.InputWrapper>
	)
}

export default memo(AutocompleteEditable)
