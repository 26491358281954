import { metricsConstants } from '../_constants'

export function metrics(state = {}, action) {
	switch (action.type) {
		case metricsConstants.SUCCESS:
			return {
				...state,
				metrics: action.data
			}
		case metricsConstants.FAILURE:
			return {}
		case metricsConstants.REQUEST:
			return state
		default:
			return state
	}
}
