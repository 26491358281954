import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'semantic-ui-react'
import { userActions } from '../../../app/_actions'

import * as S from './styles'

const fields = [
	{
		type: 'password',
		name: 'currentPassword',
		label: 'Senha atual',
		icon: 'lock',
		placeholder: 'Informe sua senha atual',
		required: true
	},
	{
		type: 'password',
		name: 'newPassword',
		label: 'Nova senha',
		icon: 'lock',
		placeholder: 'Informe sua nova senha',
		required: true
	},
	{
		type: 'password',
		name: 'passwordConfirmation',
		label: 'Confirme nova senha',
		icon: 'lock',
		placeholder: 'Informe a nova senha novamente',
		required: true
	}
]

const EditPassword = () => {
	const dispatch = useDispatch()

	const [submitted, setSubmitted] = useState(false)
	const [inputs, setInputs] = useState({
		currentPassword: '',
		newPassword: '',
		passwordConfirmation: ''
	})

	const { loading } = useSelector((state) => state.users)

	function handleChange(e) {
		e.preventDefault()
		const { name, value } = e.target
		setInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	function handleSubmit(e) {
		e.preventDefault()

		const { currentPassword, newPassword, passwordConfirmation } = inputs
		setSubmitted(true)
		if (currentPassword && newPassword && passwordConfirmation) {
			dispatch(
				userActions.changePassword(
					currentPassword,
					newPassword,
					passwordConfirmation
				)
			)
		}
	}

	return (
		<S.WrappPassword>
			<S.EditInfo>
				<Form onSubmit={handleSubmit} size="large" loading={loading}>
					{fields.map((field, key) => (
						<Form.Input
							key={key}
							type={field.type}
							label={`${field.label} ${
								field.required ? '*' : ''
							}`}
							error={
								submitted &&
								field.required &&
								!inputs[field.name]
							}
							name={field.name}
							placeholder={field.placeholder}
							onChange={handleChange}
							fluid
							icon={field.icon}
							size="big"
							iconPosition="left"
						/>
					))}

					<Button
						className="styled"
						style={{ marginTop: '10px' }}
						fluid
						size="big"
					>
						Alterar senha
					</Button>
				</Form>
			</S.EditInfo>
		</S.WrappPassword>
	)
}

export default EditPassword
