import { ReactComponent as admin } from '../../../assets/dashboard-menu/admin.svg'
import { ReactComponent as bookmark } from '../../../assets/dashboard-menu/bookmark.svg'
import { ReactComponent as conta } from '../../../assets/dashboard-menu/conta.svg'
import { ReactComponent as conteudos } from '../../../assets/dashboard-menu/conteudos.svg'
import { ReactComponent as curadoria } from '../../../assets/dashboard-menu/curadoria.svg'
import { ReactComponent as painel } from '../../../assets/dashboard-menu/painel.svg'
import { ReactComponent as sair } from '../../../assets/dashboard-menu/sair.svg'
import { collectionTypeList } from '../../../app/_constants'

const menuCollections = collectionTypeList.map((collectionTypeObject) => {
	return {
		name: `${collectionTypeObject.genre === 'a' ? 'Minhas' : 'Meus'} ${
			collectionTypeObject.plural
		}`,
		href: `/painel/coleções/${collectionTypeObject.plural.toLowerCase()}`,
		icon: collectionTypeObject.icon,
		requiredRoles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION', 'ROLE_CURADOR'],
		requiredPermission: ['CREATE_COLLECTIONS']
	}
})

export const menuList = [
	{
		name: 'Painel',
		href: '/painel',
		icon: painel
	},
	{
		name: 'Minha lista',
		href: '/painel/minha-conta/favoritos',
		icon: bookmark
	},
	{
		name: 'Meus Conteúdos',
		href: '/painel/conteúdo',
		icon: conteudos,
		requiredRoles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION', 'ROLE_CURADOR'],
		requiredPermission: ['CREATE_CONTENT']
	},
	...menuCollections,
	{
		name: 'Minha conta',
		href: '/painel/minha-conta/perfil',
		icon: conta
	},
	{
		name: 'Curadoria',
		href: '/painel/curadoria',
		icon: curadoria,
		requiredRoles: [
			'ROLE_ADMIN',
			'ROLE_ORGANIZATION',
			'ROLE_CURADOR_CHEFE',
			'ROLE_CURADOR'
		]
	},
	{
		name: 'Pendências',
		href: '/painel/administração/visão-geral',
		icon: admin,
		requiredRoles: ['ROLE_ADMIN']
	},
	{
		name: 'Gerenciar Usuárias/os',
		href: '/painel/administração/usuárias',
		icon: admin,
		requiredRoles: ['ROLE_ADMIN']
	},
	{
		name: 'Gerenciar Organizações',
		href: '/painel/administração/organizações',
		icon: admin,
		requiredRoles: ['ROLE_ADMIN']
	},
	{
		name: 'Gerenciar Conteúdos',
		href: '/painel/administração/conteúdos',
		icon: admin,
		requiredRoles: ['ROLE_ADMIN']
	},
	{
		name: 'Sair',
		href: '/logout',
		icon: sair
	}
]
