export const userConstants = {
	REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
	REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
	REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
	UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
	UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

	CHANGEPHOTO_REQUEST: 'USERS_CHANGEPHOTO_REQUEST',
	CHANGEPHOTO_SUCCESS: 'USERS_CHANGEPHOTO_SUCCESS',
	CHANGEPHOTO_FAILURE: 'USERS_CHANGEPHOTO_FAILURE',

	CHANGEPASSWORD_REQUEST: 'USERS_CHANGEPASSWORD_REQUEST',
	CHANGEPASSWORD_SUCCESS: 'USERS_CHANGEPASSWORD_SUCCESS',
	CHANGEPASSWORD_FAILURE: 'USERS_CHANGEPASSWORD_FAILURE',

	NEWPASSWORD_REQUEST: 'USERS_NEWPASSWORD_REQUEST',
	NEWPASSWORD_SUCCESS: 'USERS_NEWPASSWORD_SUCCESS',
	NEWPASSWORD_FAILURE: 'USERS_NEWPASSWORD_FAILURE',

	CONFIRM_REQUEST: 'CONFIRM_EMAIL_REQUEST',
	CONFIRM_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
	CONFIRM_FAILURE: 'CONFIRM_EMAIL_FAILURE',

	FORGOT_REQUEST: 'USERS_FORGOT_REQUEST',
	FORGOT_SUCCESS: 'USERS_FORGOT_SUCCESS',
	FORGOT_FAILURE: 'USERS_FORGOT_FAILURE',

	LOGOUT: 'USERS_LOGOUT',

	GETALL_REQUEST: 'USERS_GETALL_REQUEST',
	GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
	GETALL_FAILURE: 'USERS_GETALL_FAILURE',

	DELETE_REQUEST: 'USERS_DELETE_REQUEST',
	DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
	DELETE_FAILURE: 'USERS_DELETE_FAILURE',

	IS_VISITOR: 'USERS_IS_VISITOR'
}
