import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'
import dayjs from 'dayjs'

import { ACTIONS } from '../../constants'

const ActionColumnTable = ({
	item,
	onEdit = null,
	onDelete = null,
	setSelectedItem = null,
	setOpenConfirm = null,
	onPublish = null,
	onApprove = null,
	onReject = null,
	setCurator = null
	// checkStatus
}) => {
	function getAction(action, value, check = false) {
		switch (action) {
			case 'edit':
				if (onEdit) {
					if (check) {
						return true
					}

					return onEdit(value)
				}
				return false
			case 'delete':
				if (onDelete) {
					if (check) {
						return true
					}

					if (setSelectedItem) {
						setSelectedItem(value)
					}
					if (setOpenConfirm) {
						setOpenConfirm(true)
					}
					return true
				}
				return false
			case 'publish':
				if (onPublish) {
					if (check) {
						return true
					}

					return onPublish(value)
				}
				return false
			case 'curator':
				if (setCurator) {
					if (check) {
						return true
					}

					return setCurator(value)
				}
				return false
			case 'approve':
				if (onApprove) {
					if (check) {
						return true
					}

					return onApprove(value)
				}
				return false
			case 'reject-analysis':
				if (onReject) {
					if (check) {
						return true
					}

					return onReject(value)
				}
				return false
			default:
				return false
		}
	}

	return ACTIONS.map((action, key) => {
		let hasAction = getAction(action.type, item, true)
		if (!hasAction) {
			return null
		}

		const disabled =
			// checkStatus &&
			item.status &&
			action.enableStatus &&
			!action.enableStatus.includes(item.status) &&
			action.requiredStatus &&
			!action.requiredStatus.includes(item.status)

		if (action.type !== 'approve') {
			if (disabled) {
				return (
					<Icon
						key={`action_${action.icon}_${key}`}
						disabled={true}
						// @ts-ignore
						name={action.icon}
						// @ts-ignore
						color={action.color}
						style={{
							margin: '0 5px'
						}}
					></Icon>
				)
			}
			return (
				<Popup
					key={`action_${action.icon}_${key}`}
					content={disabled ? undefined : action.popup}
					size="huge"
					trigger={
						<Icon
							disabled={disabled}
							// @ts-ignore
							name={action.icon}
							// @ts-ignore
							color={action.color}
							style={{
								cursor: 'pointer',
								margin: '0 5px'
							}}
							onClick={() => getAction(action.type, item)}
						></Icon>
					}
				/>
			)
		}

		const curators_history_formatted = (
			<>
				{item.curators_history &&
					item.curators_history.map((h, index) => (
						<React.Fragment
							key={`wrapper_${h.action_type}_${index}`}
						>
							<br />
							<div key={h.action_type}>
								{h.action_type} em{' '}
								{dayjs(h.created_at).format('DD/MM/YYYY HH:mm')}
							</div>
						</React.Fragment>
					))}
			</>
		)

		return (
			<Popup
				wide
				key={`action_${action.icon}`}
				header={action.popup}
				content={curators_history_formatted}
				size="huge"
				trigger={
					<Icon
						disabled={disabled}
						// @ts-ignore
						name={action.icon}
						// @ts-ignore
						color={action.color}
						size="large"
						style={{
							cursor: 'pointer',
							margin: '0 10px'
						}}
						onClick={() => getAction(action.type, item)}
					></Icon>
				}
			/>
		)
	})
}

export default ActionColumnTable
