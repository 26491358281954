import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { hasRoleOrPermission } from '../../utils/functions'
import history from '../../app/history'

/*
** User roles from db proteja.roles;
**	ROLE_ADMIN
	ROLE_USER
	ROLE_ORGANIZATION
	ROLE_CURADOR_CHEFE
	ROLE_CURADOR
*/

/*
** User permissions from db.permissions;
**	CREATE_CONTENT
	DELETE_ALL_CONTENT
	UPDATE_ALL_CONTENT
	APPROVE_CONTENT
	APPROVE_ALL_CONTENT
	DEFINE_CURATOR_TO_CONTENT
	CREATE_COLLECTIONS
	UPDATE_ALL_USERS
	RETRIEVE_ALL_USERS
	DELETE_ALL_USERS
	UPDATE_USER_ROLES
	UPDATE_ROLE_PERMISSIONS
	UPDATE_ABOUT_PAGE
	CRUD_CONTENT_TYPES
*/

const PrivateRoute = ({ component: Component, ...rest }) => {
	// @ts-ignore
	const { loggedIn, user } = useSelector((state) => state.authentication)
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!loggedIn) {
					// not logged in so redirect to home page with the return url
					history.replace('/')
					//precisa atualizar a tela para trocar o menu do wordpress
					window.location.reload()
					return <></>
				}

				// logged in so return component if has required role or permission
				if (
					hasRoleOrPermission(
						rest.requiredRoles,
						user.roles,
						rest.requiredPermission,
						user.permissions
					)
				) {
					return <Component {...props} />
				}

				return (
					<Redirect
						to={{
							pathname: '/painel'
						}}
					/>
				)
			}}
		/>
	)
}

export default PrivateRoute
