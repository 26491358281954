import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '../../Icon'
import MyFavorites from '../MyFavorites'

import * as S from './styles'
import history from '../../../app/history'
import CurationTable from './CurationTable'
import { collectionsActions, metricsActions } from '../../../app/_actions'
import EditContentWrapper from '../EditContent/EditContentWrapper'
import EditCollectionWrapper from '../EditCollection/EditCollectionWrapper'
import ContentOrCollectionSummary from './ContentOrCollectionSummary'
import { collectionTypeList } from '../../../app/_constants'

const Dashboard = () => {
	const dispatch = useDispatch()
	// @ts-ignore
	const { metrics } = useSelector((state) => state.metrics)
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [selectedContentId, setSelectedContentId] = useState(null)

	const [collectionModalOpen, setCollectionModalOpen] = useState(false)
	const [collectionTypeObject, setCollectionTypeObject] = useState(null)
	// const [selectedCollectionId, setSelectedCollectionId] = useState(null)

	const isCuratorChief = user.roles.includes('ROLE_CURADOR_CHEFE')
	const isCurator = user.roles.includes('ROLE_CURADOR')
	const isAdmin = user.roles.includes('ROLE_ADMIN')

	useEffect(() => {
		dispatch(metricsActions.getPainelMetrics())
	}, [dispatch])

	const handleOpenContentModal = useCallback(() => {
		setContentModalOpen(true)
	}, [])

	const resetContentId = useCallback(() => {
		setContentModalOpen(false)
		setSelectedContentId(null)
	}, [])

	const onPublishClose = () => {
		history.push('/painel/conteúdo')
	}

	const handleOpenCollectionModal = useCallback((newCollectionTypeObject) => {
		dispatch(collectionsActions.cleanCollection())
		setCollectionTypeObject(newCollectionTypeObject)
		setCollectionModalOpen(true)
	}, [])

	// const resetCollectionId = useCallback(() => {
	// 	setCollectionTypeObject(null)
	// 	setCollectionModalOpen(false)
	// 	setSelectedCollectionId(null)
	// }, [])

	if (!metrics) {
		return null
	}

	const collectionTypeItems = collectionTypeList.map(
		(collectionTypeObjectInner) => {
			return {
				title: collectionTypeObjectInner.plural,
				icon: undefined,
				value: metrics[`${collectionTypeObjectInner.slug}s`],
				subItem: [
					{
						title: 'Favoritos',
						icon: 'fad fa-star',
						value: metrics[
							`favorite_${collectionTypeObjectInner.slug}s`
						],
						iconStyle: { color: '#F7CB03' }
					},
					{
						title: 'Gostei',
						icon: 'fad fa-thumbs-up',
						iconStyle: { color: 'rgb(62, 166, 255)' },
						value: metrics[
							`liked_${collectionTypeObjectInner.slug}s`
						]
					}
				],
				collectionTypeSlug: collectionTypeObjectInner.slug
			}
		}
	)

	const items = [
		{
			title: 'Usuárias/os',
			icon: 'fad fa-users',
			value: metrics.users,
			subItem: [],
			collectionTypeSlug: null
		},
		{
			title: 'Organizações',
			icon: 'fad fa-building',
			value: metrics.organizations,
			subItem: [],
			collectionTypeSlug: null
		},
		{
			title: 'Conteúdos',
			icon: 'fad fa-books-medical',
			value: metrics.content_indications,
			subItem: [
				{
					title: 'Favoritos',
					icon: 'fad fa-star',
					value: metrics.favorite_content_indications,
					iconStyle: { color: '#F7CB03' }
				},
				{
					title: 'Gostei',
					icon: 'fad fa-thumbs-up',
					iconStyle: { color: 'rgb(62, 166, 255)' },
					value: metrics.liked_content_indications
				}
			],
			collectionTypeSlug: null
		},
		...collectionTypeItems
	]

	return (
		<>
			<S.Panel stackable celled="internally">
				<Grid.Row>
					<S.StyledCenteredColumn width={4} id="shortcut">
						<Icon
							type="fas fa-books-medical"
							style={{
								fontSize: '80px',
								padding: '50px 0 20px 0'
							}}
						></Icon>
						<S.Description>
							Para começar, envie e publique documentos,
							relatórios, vídeos e imagens.
						</S.Description>
						<Button
							className="styled"
							fluid
							size="big"
							onClick={handleOpenContentModal}
						>
							Enviar conteúdo
						</Button>
					</S.StyledCenteredColumn>

					<S.StyledCenteredColumn width={4} id="shortcut">
						<Icon
							type="fad fa-list-ol"
							style={{
								fontSize: '80px',
								padding: '50px 0 20px 0'
							}}
						></Icon>
						<S.Description>
							Crie e organize os conteúdos cadastrados por séries,
							cursos ou projetos.
						</S.Description>
						{collectionTypeList.map((collectionTypeObjectInner) => (
							<Button
								key={collectionTypeObjectInner.slug}
								className="styled"
								fluid
								size="big"
								onClick={() =>
									handleOpenCollectionModal(
										collectionTypeObjectInner
									)
								}
							>
								Enviar{' '}
								{collectionTypeObjectInner.name.toLowerCase()}
							</Button>
						))}
					</S.StyledCenteredColumn>
					<Grid.Column width={8} id="summary">
						<div>
							{items.map((item, key) => {
								return (
									<ContentOrCollectionSummary
										key={key}
										title={item.title}
										icon={item.icon}
										collectionTypeSlug={
											item.collectionTypeSlug
										}
										// iconStyle={item.iconStyle}
										value={item.value}
										subItem={item.subItem}
									/>
								)
							})}
						</div>
					</Grid.Column>
				</Grid.Row>

				{(isAdmin || isCuratorChief || isCurator) && (
					<Grid.Row id="content">
						<Grid.Column width={16}>
							<CurationTable />
						</Grid.Column>
					</Grid.Row>
				)}
				<Grid.Row>
					<Grid.Column width={16}>
						<MyFavorites
							title="Meus últimos favoritos"
							size="small"
							showMore
						/>
					</Grid.Column>
				</Grid.Row>
			</S.Panel>
			<EditContentWrapper
				selectedContentId={selectedContentId}
				setSelectedContentId={setSelectedContentId}
				contentModalOpen={contentModalOpen}
				setContentModalOpen={setContentModalOpen}
				resetContentId={resetContentId}
				onClose={onPublishClose}
			/>
			<EditCollectionWrapper
				collectionTypeObject={collectionTypeObject}
				collectionModalOpen={collectionModalOpen}
				setCollectionModalOpen={setCollectionModalOpen}
			/>
		</>
	)
}

export default Dashboard
