import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dropdown, Icon as SemanticIcon } from 'semantic-ui-react'
import QueryString from 'qs'

import * as S from './styles'
import { useFilter } from '../../../context/advancedFilter'
import SearchResultList from '../../../components/PublicPages/SearchResultList'
import { searchActions } from '../../../app/_actions'
import { contentDataActions, locationActions } from '../../../app/_actions'
import GroupLocation from '../../../components/PublicPages/Search/LocationSearch'
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions'
import SearchBox from '../../../components/PublicPages/SearchBox'
import ContentOrCollectionTypeIcon from '../../../components/ContentOrCollectionTypeIcon'

import 'semantic-ui-css/semantic.min.css'

const sortModel = {
	field: 'created_at',
	sort: 'desc'
}

const SearchResult = () => {
	const dispatch = useDispatch()
	// @ts-ignore
	const { filter, setFilter } = useFilter()
	const [searchText, setSearchText] = useState('')
	const [isAdvancedSearchVisible, setIsAdvancedSearchVisible] =
		useState(false)
	const { t, i18n } = useTranslation()
	// @ts-ignore
	const { contentsAndCollections } = useSelector((state) => state.search)
	// @ts-ignore
	const { types } = useSelector((state) => state.types)
	const [shownTypes, setShownTypes] = useState(types)

	// const search = useLocation().search
	const location = useLocation()
	const search = location.search
	const history = useHistory()
	const { width } = useWindowDimensions()
	const isWpLoggedIn = document.getElementsByClassName('logged-in').length
	const MORE_BUTTON_MAX_SCREEEN_WIDTH = 1770
	const MORE_BUTTON_MIN_SCREEEN_WIDTH = 770
	const PER_PAGE = 12
	const page = useRef(0)

	useEffect(() => {
		const dataTypes = Object.assign([], types)
		if (width > MORE_BUTTON_MIN_SCREEEN_WIDTH) {
			if (width < MORE_BUTTON_MAX_SCREEEN_WIDTH) {
				dataTypes.pop()
			}
			if (width < 1730) {
				dataTypes.pop()
			}
			if (width < 1620) {
				dataTypes.pop()
			}
			if (width < 1470) {
				dataTypes.pop()
			}
			if (width < 1330) {
				dataTypes.pop()
			}
			if (width < 1160) {
				dataTypes.pop()
			}
		}
		setShownTypes(dataTypes)
	}, [width, types])

	function handleChange({ target }) {
		setSearchText(target.value)
	}

	function getQueryParams(filterToApply) {
		const queryStringAr = []
		if (filterToApply.q) {
			queryStringAr.push(`busca=${filterToApply.q}`)
		}
		if (filterToApply.content_type_id.length) {
			queryStringAr.push(
				`content_type_id=${filterToApply.content_type_id[0].id}`
			)
			queryStringAr.push(
				`content_type=${filterToApply.content_type_id[0].name}`
			)
		}
		const advancedFilterParams = [
			'bioma_id',
			'language_id',
			'land_category_id',
			'pais_id',
			'estado_id',
			'theme_id',
			'year'
		]

		for (let advancedFilterParam of advancedFilterParams) {
			const params = filterToApply[advancedFilterParam]
			if (params.length) {
				for (let param of params) {
					queryStringAr.push(
						`${advancedFilterParam}[]=${param.id || param}`
					)
				}
			}
		}
		return `?${queryStringAr.join('&')}`
	}

	const handleFilter = useCallback(
		(filterToApply) => {
			setFilter(filterToApply)
			if (contentsAndCollections.loading) {
				return
			}
			page.current += 1
			const dispatchAction =
				page.current === 1
					? searchActions.getFilteredResults
					: searchActions.loadMoreFilteredResults
			if (!filterToApply.q) {
				dispatch(
					dispatchAction(
						{ ...filterToApply, status: 1 },
						page.current,
						PER_PAGE,
						i18n.language,
						sortModel.field,
						sortModel.sort
					)
				)
			} else {
				dispatch(
					dispatchAction(
						{ ...filterToApply, status: 1 },
						page.current,
						PER_PAGE,
						i18n.language
					)
				)
			}
			setTimeout(() => {
				window.scrollTo(0, 0)
			}, 500)
		},
		[setFilter, dispatch, i18n.language, contentsAndCollections.loading]
	)

	function handleKeyDown(event) {
		if (event.key === 'Enter') {
			handleFilter({ ...filter, q: searchText })
		}
	}

	useEffect(() => {
		if (filter) {
			if (i18n.language == 'es-ES') {
				history.push({
					pathname: '/resultado-busqueda',
					search: getQueryParams(filter)
				})
			} else {
				history.push({
					pathname: '/resultado-busca',
					search: getQueryParams(filter)
				})
			}
		}
	}, [filter])

	useEffect(() => {
		if (search) {
			const newSearch = QueryString.parse(search, {
				ignoreQueryPrefix: true
			})

			let newFilter = filter
			if (newSearch.busca) {
				// @ts-ignore
				setSearchText(newSearch.busca)
				newFilter = { ...newFilter, q: newSearch.busca }
			}
			if (newSearch.theme_id) {
				newFilter = { ...newFilter, theme_id: [newSearch.theme_id] }
			}
			if (newSearch.content_type_id) {
				newFilter = {
					...newFilter,
					content_type_id: [
						{
							id: newSearch.content_type_id,
							name: newSearch.content_type
						}
					]
				}
			}
			if (newSearch.bioma_id) {
				newFilter = {
					...newFilter,
					bioma_id: newSearch.bioma_id
				}
			}
			if (newSearch.language_id) {
				newFilter = {
					...newFilter,
					language_id: newSearch.language_id
				}
			}
			if (newSearch.land_category_id) {
				newFilter = {
					...newFilter,
					land_category_id: newSearch.land_category_id
				}
			}
			if (newSearch.pais_id) {
				newFilter = {
					...newFilter,
					pais_id: newSearch.pais_id
				}
			}
			if (newSearch.estado_id) {
				newFilter = {
					...newFilter,
					estado_id: newSearch.estado_id
				}
			}
			if (newSearch.theme_id) {
				newFilter = {
					...newFilter,
					theme_id: newSearch.theme_id
				}
			}
			if (newSearch.year) {
				newFilter = {
					...newFilter,
					year: newSearch.year
				}
			}
			handleFilter(newFilter)
		}
	}, [search])

	function handleChangeType(item) {
		handleFilter({ ...filter, content_type_id: [item] })
	}

	function cleanTypeFilter() {
		handleFilter({ ...filter, content_type_id: [] })
	}

	const topics = [
		{
			text: t('search.themes.biodiversity.title', 'Biodiversidade'),
			value: 1,
			id: 1
		},
		{
			text: t('search.themes.forest-water.title', 'Floresta e água'),
			value: 2,
			id: 2
		},
		{
			text: t('search.themes.threats.title', 'Pressões e ameaças'),
			value: 3,
			id: 3
		},
		{
			text: t('search.themes.green-economy.title', 'Economia verde'),
			value: 4,
			id: 4
		},
		{
			text: t(
				'search.themes.people-territory.title',
				'Povos e territórios'
			),
			value: 5,
			id: 5
		},
		{
			text: t('search.themes.management.title', 'Gestão'),
			value: 6,
			id: 6
		}
	].sort((a, b) => a.text.localeCompare(b.text))

	const { countries, fetchingCountries } = useSelector(
		// @ts-ignore
		(state) => state.countries
	)
	const dataCountries = useMemo(
		() =>
			countries
				.map((row) => ({
					key: row.id.M49,
					text: row.nome,
					value: row.id.M49
				}))
				.sort((a, b) => {
					if (a.text == 'Brasil') return -1
					else if (b.text == 'Brasil') return 1
					else return a.text.localeCompare(b.text)
				}),
		[countries]
	)

	// @ts-ignore
	const { biomes, fetchingBiomes } = useSelector((state) => state.biomes)
	const dataBiomes = useMemo(
		() =>
			biomes
				? biomes.map((row) => ({
						key: row.id,
						text: row.name,
						value: row.id
				  }))
				: [],
		[biomes]
	)

	// @ts-ignore
	const { states, fetchingStates } = useSelector((state) => state.states)
	const dataStates = useMemo(
		() =>
			states
				? states
						.map((row) => ({
							key: row.id,
							text: row.nome,
							value: row.id
						}))
						.sort((a, b) => a.text.localeCompare(b.text))
				: [],
		[states]
	)

	const { landCategories, fetchingLandCategories } = useSelector(
		// @ts-ignore
		(state) => state.landCategories
	)
	const dataLandCategories = useMemo(
		() =>
			landCategories.map((row) => ({
				key: row.id,
				text: row.name,
				value: row.id
			})),
		[landCategories]
	)

	useEffect(() => {
		dispatch(contentDataActions.getAllTypes())
		dispatch(locationActions.getBiomes())
		dispatch(locationActions.getCountries())
		dispatch(locationActions.getStates())
		dispatch(locationActions.getLandCategories())
	}, [dispatch])

	return (
		<>
			<div className="page">
				<S.Wrapp isWpLoggedIn={isWpLoggedIn}>
					<SearchBox
						isHome={false}
						value={searchText}
						handleChange={handleChange}
						handleFilter={() => {
							handleFilter({ ...filter, q: searchText })
						}}
						handleKeyDown={handleKeyDown}
					/>
				</S.Wrapp>
				<S.Filters isWpLoggedIn={isWpLoggedIn}>
					<S.Types>
						<S.TypeItem
							name="Tudo"
							active={filter.content_type_id === 0}
							onClick={() => cleanTypeFilter()}
						>
							<SemanticIcon name="search" />
							{t('search.option-all', 'Tudo')}
						</S.TypeItem>
						{shownTypes.map((item) => {
							return (
								<S.TypeItem
									key={item.id}
									name={item.name}
									active={filter.content_type_id.some(
										(type) => item.id === type.id
									)}
									onClick={() => handleChangeType(item)}
								>
									<ContentOrCollectionTypeIcon
										id={item.id}
										color="#b0bec5"
									/>
									{item.name}
								</S.TypeItem>
							)
						})}
						{width < MORE_BUTTON_MAX_SCREEEN_WIDTH &&
							width > MORE_BUTTON_MIN_SCREEEN_WIDTH && (
								<S.TypeItem className="more-item">
									<Dropdown
										// text={
										// 	<>
										// 		<SemanticIcon name="ellipsis vertical" />
										// 		Mais
										// 	</>
										// }
										text={t('search.option-more', 'Mais')}
										icon=""
										inline
										simple
										value={filter.content_type_id.id}
										onChange={(_, data) => {
											handleChangeType(
												// @ts-ignore
												JSON.parse(data.value)
											)
										}}
										options={types
											.filter(
												(type) =>
													!shownTypes
														.map((t) => t.id)
														.includes(type.id)
											)
											.map((type) => {
												return {
													key: type.id,
													text: type.name,
													value: JSON.stringify(type),
													icon: type.icon_name
												}
											})}
									/>
								</S.TypeItem>
							)}
						<S.ButtonContainer>
							<S.AdvancedFiltersButton
								onClick={() =>
									setIsAdvancedSearchVisible(
										(prevState) => !prevState
									)
								}
							>
								<SemanticIcon
									name="filter"
									style={{
										color: '#b0bec5',
										fontSize: '10px'
									}}
								/>
								<S.AdvancedFiltersButtonTitle>
									{t(
										'search.advanced-search',
										'Busca Avançada'
									)}
								</S.AdvancedFiltersButtonTitle>
								{isAdvancedSearchVisible ? (
									<SemanticIcon
										name="caret up"
										style={{ color: '#b0bec5' }}
									/>
								) : (
									<SemanticIcon
										name="caret down"
										style={{ color: '#b0bec5' }}
									/>
								)}
							</S.AdvancedFiltersButton>
						</S.ButtonContainer>
					</S.Types>
					<S.AdvancedFilters
						className={isAdvancedSearchVisible && 'clicked'}
					>
						<GroupLocation
							fetchingBiomes={fetchingBiomes}
							dataBiomes={dataBiomes}
							fetchingLandCategories={fetchingLandCategories}
							dataLandCategories={dataLandCategories}
							fetchingCountries={fetchingCountries}
							dataCountries={dataCountries}
							fetchingStates={fetchingStates}
							dataStates={dataStates}
							topics={topics}
						/>
						<S.ButtonContainer>
							<S.CloseAdvancedFiltersButton
								onClick={() =>
									setIsAdvancedSearchVisible(false)
								}
							>
								{t('search.close', 'Fechar')}

								<SemanticIcon
									name="close"
									style={{ color: '#424242' }}
								/>
							</S.CloseAdvancedFiltersButton>
						</S.ButtonContainer>
					</S.AdvancedFilters>
				</S.Filters>
				<div id="content">
					<S.AdvancedFiltersFakeDiv
						className={isAdvancedSearchVisible && 'clicked'}
					/>
					<SearchResultList
						data={contentsAndCollections}
						dataBiomes={dataBiomes}
						dataLandCategories={dataLandCategories}
						dataCountries={dataCountries}
						dataStates={dataStates}
						topics={topics}
						perPage={PER_PAGE}
						screenWidth={width}
					/>
				</div>
			</div>
		</>
	)
}

export default SearchResult
