import React from 'react'
import { withRouter } from 'react-router-dom'

import ManageContentWrapper from '../ManageContentWrapper'

const AllContent = () => {
	return (
		<div style={{ width: '100%', overflow: 'hidden' }}>
			<h1>Gerenciar Conteúdos</h1>
			<ManageContentWrapper isUserContent={false} />
		</div>
	)
}

export default withRouter(AllContent)
