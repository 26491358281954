import styled from 'styled-components'
import theme from '../../styles/theme'
import Slider from 'react-slick'

export const ContentBlockWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 8px;
`

export const SharingBar = styled.div`
	display: flex;
	width: 100%;
	padding: 16px;
	gap: 8px;
	background: var(--brown-lighten-5, #efebe9);
	.MuiInputBase-root {
		background: #fff;
	}
	.MuiTextField-root {
		flex: 1;
		margin-right: 8px;
	}
`
export const CopyLink = styled.a`
	text-decoration: underline;
	color: ${() => theme.colors.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`

export const ArrowIconContainer = styled.span``

export const Arrow = styled.a`
	position: absolute;
	color: #fff;
	height: 100%;
	display: flex !important;
	opacity: 0;
	transition: all 0.5s ease;
	align-items: center;
	text-decoration: none;
	font-size: 6em;
	font-family: Arial;
	width: 7vw;
	padding: 20px;
	text-align: center;
	z-index: 1;
	// @media screen and (max-width: 768px) {
	// 	opacity: 1;
	// }
	:hover {
		cursor: pointer;
		color: #fff;
		font-size: 7em;
	}
	&.slick-disabled ${ArrowIconContainer} {
		display: none;
	}
	:before {
		content: '';
	}
	@media screen and (max-width: 782px) {
		opacity: 1;
		font-size: 3em;
		background: unset !important;
		padding: 2px;
		:hover {
			font-size: 3em;
		}
	}
`

export const ArrowLeft = styled(Arrow)`
	left: 0;
	justify-content: flex-start;
	background: linear-gradient(
		-90deg,
		rgba(0, 0, 0, 0) 0%,
		rgb(10 10 10 / 80%) 200%
	);
	:hover {
		background: linear-gradient(
			-90deg,
			rgba(0, 0, 0, 0) 0%,
			rgb(10 10 10 / 100%) 200%
		);
	}
	&.slick-disabled:hover {
		background: linear-gradient(
			-90deg,
			rgba(0, 0, 0, 0) 0%,
			rgb(10 10 10 / 80%) 200%
		);
	}
`

export const ArrowRight = styled(Arrow)`
	right: 0;
	justify-content: flex-end;
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0) 0%,
		rgb(10 10 10 / 80%) 200%
	);
	:hover {
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0) 0%,
			rgb(10 10 10 / 100%) 200%
		);
	}
	&.slick-disabled:hover {
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0) 0%,
			rgb(10 10 10 / 80%) 200%
		);
	}
`
export const SliderWrapper = styled(Slider).attrs((props) => ({
	margin: props.margin
}))`
	width: 100vw;
	padding-top: 96px;
	${({ margin }) =>
		`margin: ${margin || '0'};
`}
	padding-bottom: 32px;
	transition: all 0.5s ease;
	.slick-list {
		padding: 0 90px 0 90px;
	}
	@media screen and (max-width: 782px) {
		.slick-list {
			padding: 0;
		}
		.slick-slide {
			display: flex;
			justify-content: center;
		}
	}
	.slick-track {
		display: flex;
	}
	.slick-slide {
		width: 356px;
	}
	:hover ${Arrow} {
		opacity: 1;
	}
`
