import React from 'react'

import Icon from '../../../Icon'
import * as S from '../styles'
import ContentOrCollectionTypeIcon from '../../../ContentOrCollectionTypeIcon'
import { collectionTypeIds } from '../../../../app/_constants'

const ContentOrCollectionSummary = ({
	title,
	icon,
	collectionTypeSlug,
	value,
	subItem
}) => {
	return (
		<S.WrappItem>
			<div>
				<h3>{title}</h3>
				<S.ItemDesc>
					{icon && <Icon type={icon}></Icon>}
					{collectionTypeSlug && (
						<i>
							<ContentOrCollectionTypeIcon
								id={collectionTypeIds[collectionTypeSlug]}
								size={36}
								color="#194D48"
							/>
						</i>
					)}
					<h2>{value}</h2>
				</S.ItemDesc>
			</div>
			{subItem &&
				subItem.length > 0 &&
				subItem.map((item, key) => (
					<div key={key}>
						<h3>{item.title}</h3>
						<S.ItemDesc>
							<Icon
								type={item.icon}
								style={item.iconStyle}
							></Icon>
							<h2>{item.value}</h2>
						</S.ItemDesc>
					</div>
				))}
		</S.WrappItem>
	)
}

export default ContentOrCollectionSummary
