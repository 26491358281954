import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Loader, Dimmer, Image, Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as S from './styles'

import { orgsActions, userActions } from '../../../app/_actions'
import { getRoleLabel, isTmpId } from '../../../utils/functions'
import Icon from '../../Icon'
import AutocompleteEditable from '../Autocomplete/AutocompleteEditable'

const fields = [
	{
		type: 'text',
		name: 'name',
		label: 'Nome',
		icon: 'user',
		placeholder: 'Informe seu nome',
		required: true
	},
	{
		type: 'email',
		name: 'email',
		label: 'E-mail',
		icon: 'mail',
		placeholder: 'Informe seu e-mail',
		required: true
	}
]

const MyAccount = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// @ts-ignore
	const { user } = useSelector((state) => state.users)

	// @ts-ignore
	const { user: authUser } = useSelector((state) => state.authentication)

	// @ts-ignore
	const { orgs, fetchingOrgs } = useSelector((state) => state.orgs)

	const [isEditing, setEditing] = useState(false)
	const [inputs, setInputs] = useState(null)

	function submitUser() {
		const { name, organization, email } = inputs
		dispatch(
			userActions.updateUser(user.id, {
				name,
				email,
				organization_id: organization.id
			})
		)
		setEditing(false)
	}

	function handleChange({ target }) {
		const { name: fieldKey, value } = target
		setInputs((inputs) => ({ ...inputs, [fieldKey]: value }))
	}

	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			setInputs((inputs) => ({
				...inputs,
				file: URL.createObjectURL(file)
			}))
			dispatch(userActions.changePhoto(file))
		}
	}

	const onChangeOrg = ({ target }) => {
		const { name: fieldKey, value: newValueRaw } = target
		if (!newValueRaw || !isTmpId(newValueRaw.id)) {
			setInputs((inputs) => ({ ...inputs, [fieldKey]: newValueRaw }))
			return
		}

		dispatch(
			orgsActions.create({ name: newValueRaw.name }, (newValue) => {
				setInputs((inputs) => ({ ...inputs, [fieldKey]: newValue }))
			})
		)
	}

	useEffect(() => {
		if (!orgs || orgs.length === 0) {
			dispatch(orgsActions.getAll())
		}
	}, [dispatch, orgs])

	useEffect(() => {
		if (!user && authUser) {
			dispatch(userActions.setUser(authUser))
		}
	}, [dispatch, user, authUser])

	useEffect(() => {
		if (user) {
			setInputs({
				name: user.name,
				organization: user.organization,
				email: user.email
			})
		}
	}, [user])

	if (!inputs) {
		return (
			<Dimmer>
				<Loader>Carregando...</Loader>
			</Dimmer>
		)
	}

	return (
		<div style={{ padding: 16, display: 'flex' }}>
			<S.AvatarInfo>
				<h2>Perfil</h2>
				{inputs['file'] || user.photo_id ? (
					<>
						<Image
							style={{
								height: 150,
								width: 150,
								margin: '10px 30px'
							}}
							circular
							src={user.photo_id ? user.photo_id : inputs['file']}
							size="small"
						/>
					</>
				) : (
					<Icon
						type="fa fa-user"
						style={{ fontSize: '100px', margin: '20px 0' }}
					/>
				)}

				<div>
					<Button
						className="styled"
						as="label"
						htmlFor="file"
						fluid
						size="big"
					>
						Escolher foto
					</Button>
					<input
						onChange={onFileChange}
						type="file"
						accept="image/*"
						id="file"
						style={{ display: 'none' }}
					/>
					<Label>Escolha uma imagem de até 150px</Label>
				</div>
			</S.AvatarInfo>
			<S.EditInfo>
				{isEditing ? (
					<>
						{fields.map((field, key) => {
							return (
								<Form.Input
									key={key}
									type={field.type}
									label={`${field.label} ${
										field.required ? '*' : ''
									}`}
									error={
										field.required && !inputs[field.name]
									}
									style={{ width: '300px' }}
									name={field.name}
									value={inputs[field.name]}
									placeholder={field.placeholder}
									onChange={handleChange}
									fluid
									icon={field.icon}
									size="large"
									iconPosition="left"
								/>
							)
						})}
						<AutocompleteEditable
							multiple={false}
							label={t(
								'dashboard.organizations',
								'Organização ou instituição'
							)}
							fieldKey="organization"
							placeholder={t(
								'dashboard.organization-placeholder',
								'Adicionar organização ou instituição'
							)}
							data={orgs}
							loading={fetchingOrgs}
							onChange={onChangeOrg}
							value={inputs.organization}
							allowCreateRelated={true}
						/>
					</>
				) : (
					<UserInfo
						name={user.name}
						email={user.email}
						organizationName={user ? user.organization.name : ''}
						roles={user.roles}
					></UserInfo>
				)}
				<Button
					className="styled"
					fluid
					size="big"
					onClick={() =>
						isEditing ? submitUser() : setEditing(true)
					}
				>
					{isEditing
						? 'Salvar informações do perfil'
						: 'Editar informações do perfil'}
				</Button>
				<Link to="/painel/minha-conta/alterar-senha">
					<Button style={{ marginTop: 12 }}>Alterar senha</Button>
				</Link>
			</S.EditInfo>
		</div>
	)
}

const UserInfo = ({ name, organizationName, email, roles }) => {
	return (
		<S.UserInfo>
			<div>
				<h1>Nome</h1>
				<b>{name}</b>
			</div>
			<div>
				<h1>E-mail</h1>
				<b>{email}</b>
			</div>
			<div>
				<h1>Organização</h1>
				<b>{organizationName}</b>
			</div>
			<div>
				<h1>Perfil</h1>
				{roles.map((role, index) => (
					<b key={`role_${index}`}>{getRoleLabel(role)}</b>
				))}
			</div>
		</S.UserInfo>
	)
}

export default MyAccount
