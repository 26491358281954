import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Header, Popup, Modal, Icon } from 'semantic-ui-react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import * as S from './styles'
import { userService } from '../../../../app/_services'
import { collectionsActions } from '../../../../app/_actions'
import AsyncSelect from '../../../AsyncSelect'
import DataTable from '../../../DataTable'
import PublishCollection from '../../PublishCollection'

const columns = [
	{ name: 'title', label: 'Título' },
	// { name: 'contributors', label: 'Colaboradores' },
	{
		name: 'created_at',
		label: 'Criado em',
		format: 'DD/MM/YYYY HH:mm'
	},
	{
		name: 'user',
		label: 'Criado por',
		customRender: (value, email) => {
			return (
				<div>
					{value}
					<Popup
						content={`Enviar e-mail para o ${email}`}
						flowing
						size="huge"
						hoverable
						trigger={
							<Icon
								name="mail outline"
								color="grey"
								size="large"
								style={{ margin: '0 5px', cursor: 'pointer' }}
								onClick={() =>
									// @ts-ignore
									(window.location = `mailto:${email}`)
								}
							/>
						}
					/>
				</div>
			)
		}
	},
	{
		name: 'curator',
		label: 'Curador responsável',
		customRender: (value, email) => {
			return (
				<div>
					{value}
					<Popup
						content={`Enviar e-mail para o ${email}`}
						flowing
						size="huge"
						hoverable
						trigger={
							<Icon
								name="mail outline"
								color="grey"
								size="large"
								style={{ margin: '0 5px', cursor: 'pointer' }}
								onClick={() =>
									// @ts-ignore
									(window.location = `mailto:${email}`)
								}
							/>
						}
					/>
				</div>
			)
		}
	},
	{
		name: 'status',
		label: 'Status',
		status: true,
		info: true,
		textInfoColumn: 'approve_justification',
		textInfoColumn2: 'reprove_justification'
	}
]

function CollectionToApproval({
	isAdmin,
	isCuratorChief,
	isCurator,
	contentCurators,
	collectionTypeObject
}) {
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [curator, setCurator] = useState(null)
	const [openCuratorModal, setOpenCuratorModal] = useState(false)
	const [openPublishModal, setOpenPublishModal] = useState(false)
	const [openDefineModal, setOpenDefineModal] = useState(false)
	const [defineText, setDefineText] = useState('')
	const [errorDefineText, setErrorDefineText] = useState(false)
	const [defineType, setDefineType] = useState('')
	const PER_PAGE = 5
	const collectionTypeName = collectionTypeObject.name.toLowerCase()
	const collectionTypeGenre = collectionTypeObject.genre

	// @ts-ignore
	const state = useSelector((state) => state.collections)

	const loading = state.loading
	const data = state[`${collectionTypeObject.slug}s`]
	const selectedItem = state.object

	const getData = useCallback(() => {
		dispatch(
			collectionsActions.getAllCollectionsToApprovalByType(
				page,
				PER_PAGE,
				contentCurators,
				collectionTypeObject.slug
			)
		)
	}, [dispatch, page, PER_PAGE, contentCurators, collectionTypeObject.slug])

	const handleCloseCuratorModal = () => {
		setOpenCuratorModal(false)
		setCurator(null)
		dispatch(collectionsActions.cleanCollection())
	}

	const handleClosePublishModal = () => {
		setOpenPublishModal(false)
		dispatch(collectionsActions.cleanCollection())
	}

	const handleCurator = (item) => {
		dispatch(collectionsActions.getCollectionById(item.id))
		setOpenCuratorModal(true)
		setCurator(
			item.curator.id
				? { value: item.curator.id, label: item.curator.name }
				: null
		)
	}

	const confirmCurator = () => {
		if (curator) {
			dispatch(
				collectionsActions.setCurator(
					selectedItem.id,
					curator.value,
					() => {
						handleCloseCuratorModal()
						getData()
					}
				)
			)
			handleCloseCuratorModal()
		}
	}

	const handleCloseDefineModal = () => {
		setDefineText('')
		setErrorDefineText(false)
		dispatch(collectionsActions.cleanCollection())
		setOpenDefineModal(false)
	}

	const handleApprove = (item) => {
		setOpenPublishModal(true)
		dispatch(collectionsActions.getCollectionById(item.id))
	}

	const defineCollection = (type) => {
		setOpenDefineModal(true)
		setDefineType(type)
	}

	const handleReject = (item = null) => {
		if (item) {
			dispatch(collectionsActions.getCollectionById(item.id))
		}
		defineCollection('reject-analysis')
	}

	const handleDoApprove = () => {
		defineCollection('approve')
	}

	const handleAskCorrection = () => {
		defineCollection('correct')
	}

	const confirmCollection = () => {
		if (selectedItem && (defineText || defineType === 'approve')) {
			dispatch(
				collectionsActions.defineContent(
					selectedItem.id,
					defineType,
					defineText,
					() => {
						getData()
						handleCloseDefineModal()
						handleClosePublishModal()
					}
				)
			)
			return
		}

		setErrorDefineText(true)
		dispatch(collectionsActions.cleanCollection())
	}

	const getDefineModalTitle = () => {
		switch (defineType) {
			case 'reject-analysis':
				return `Justificativa para REJEIÇÃO d${collectionTypeGenre} ${collectionTypeName}`
			case 'correct':
				return `Observações para CORREÇÃO d${collectionTypeGenre} ${collectionTypeName}`
			case 'approve':
				return `Razões para APROVAÇÃO d${collectionTypeGenre} ${collectionTypeName} (opcional)`
		}
	}

	const getDefineModalBody = () => {
		switch (defineType) {
			case 'reject-analysis':
				return 'Quais as razões para rejeitar'
			case 'correct':
				return 'O que deve ser alterado?'
			case 'approve':
				return 'Justifique as razões da aprovação'
		}
	}

	useEffect(() => {
		getData()
	}, [getData])

	const noCollectionTitle = `Nenhum${
		collectionTypeGenre === 'a' ? 'a' : ''
	} ${collectionTypeName} nov${collectionTypeGenre} disponível para avaliação.`
	const responsibleCuratorTitle = `Definir curador/a responsável pela aprovação d${collectionTypeGenre} ${collectionTypeName}`
	const publishModalTitle = `Aprovação d${collectionTypeGenre} ${collectionTypeName} selecionad${collectionTypeGenre}`

	return (
		<>
			{data && data.collections && data.collections.length > 0 ? (
				<DataTable
					prefixKey="toApproval_"
					data={data.collections}
					totalResults={data.totalResults}
					loading={loading}
					page={page}
					setPage={setPage}
					columns={columns}
					setCurator={
						isAdmin || isCuratorChief ? handleCurator : null
					}
					onApprove={
						isAdmin || isCuratorChief || isCurator
							? handleApprove
							: null
					}
					onReject={isCurator ? handleReject : null}
				/>
			) : (
				<Header>{noCollectionTitle}</Header>
			)}
			<Modal
				onClose={() => setOpenCuratorModal(false)}
				onOpen={() => setOpenCuratorModal(true)}
				open={openCuratorModal}
			>
				<Modal.Header>{responsibleCuratorTitle}</Modal.Header>
				<Modal.Content image>
					<Modal.Description>
						<label>Curador</label>
						<AsyncSelect
							loadOptions={userService.loadUsersCurator}
							onChange={(value) => setCurator(value)}
							placeholder="Selecione um/a usuário/a"
							value={curator}
						/>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						color="black"
						onClick={() => {
							setOpenCuratorModal(false)
							setCurator(null)
						}}
					>
						Cancelar
					</Button>
					<Button
						content="Definir curador/a"
						labelPosition="right"
						icon="checkmark"
						disabled={!curator}
						onClick={() => confirmCurator()}
						positive
					/>
				</Modal.Actions>
			</Modal>
			<PublishCollection
				open={openPublishModal}
				handleClose={handleClosePublishModal}
				startEdit={null}
				title={publishModalTitle}
				onDelete={null}
				onSubmitToPublish={null}
				onReject={handleReject}
				onAskCorrection={handleAskCorrection}
				onApprove={handleDoApprove}
			/>
			<Dialog open={openDefineModal} onClose={handleCloseDefineModal}>
				<DialogTitle>{getDefineModalTitle()}</DialogTitle>
				<DialogContent>
					<S.StyledTextArea
						style={{ width: '100%' }}
						value={defineText}
						error={errorDefineText}
						onChange={({ target }) => {
							setDefineText(target.value)
							setErrorDefineText(false)
						}}
						placeholder={getDefineModalBody()}
						rows={5}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="black" onClick={handleCloseDefineModal}>
						Cancelar
					</Button>
					<Button
						content="Confirmar"
						labelPosition="right"
						icon="checkmark"
						disabled={loading}
						onClick={confirmCollection}
						positive
					/>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default CollectionToApproval
