import React from 'react'
import { Grid } from 'semantic-ui-react'
import CurationTable from '../../../components/Dashboard/DashboardPanel/CurationTable'

import * as S from './styles'

function Curation() {
	return (
		<S.Panel stackable celled="internally">
			<Grid.Row>
				<Grid.Column width={16}>
					<CurationTable />
				</Grid.Column>
			</Grid.Row>
		</S.Panel>
	)
}

export default Curation
