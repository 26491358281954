import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import * as S from './styles'
import Icon from '../../components/Icon'
import ContentUserActions from '../../components/PublicPages/ContentUserActions'
import ContentInfo from './ContentInfo'
import CollectionInfo from './CollectionInfo'

import theme from '../../styles/theme'
import ContentOrCollectionTypeIcon from '../../components/ContentOrCollectionTypeIcon'
import noImg from '../../assets/no-image.jpg'
import { useTranslation } from 'react-i18next'

const ContentOrCollectionDetailDialog = ({
	item,
	handleClose,
	nextItem,
	previousItem,
	hasNext,
	hasPrev,
	open = true,
	children,
	onCollectionContentClick = null
}) => {
	const [isSharingBarVisible, setIsSharingBarVisible] = useState(false)
	const { t, i18n } = useTranslation()

	useEffect(() => {
		function keyDownListener(e) {
			if (e.key === 'ArrowLeft') {
				previousItem()
			} else if (e.key === 'ArrowRight') {
				nextItem()
			}
		}
		document.addEventListener('keydown', keyDownListener)

		return () => {
			document.removeEventListener('keydown', keyDownListener)
		}
	}, [nextItem, previousItem])

	if (!item || !item.id) {
		return null
	}

	function moreButtonLink(item) {
		if (i18n.language == 'es-ES') {
			return isCollection
				? `/${item.collection_type.slug}-es/${item.friendly_url}`
				: `/contenido/${item.friendly_url}`
		} else {
			return isCollection
				? `/${item.collection_type.slug}/${item.friendly_url}`
				: `/conteudo/${item.friendly_url}`
		}
	}

	const isCollection = !item.content_type || item.content_type.id === null

	return (
		<>
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<S.ArrowLeft
					onClick={hasPrev ? previousItem : undefined}
					hidden={!hasPrev}
				>
					‹
				</S.ArrowLeft>
				<S.ArrowRight
					onClick={hasNext ? nextItem : undefined}
					hidden={!hasNext}
				>
					›
				</S.ArrowRight>

				{(item.content_type || item.collection_type) && (
					<S.TopWrapper>
						<S.ContentTypeWrapper>
							<ContentOrCollectionTypeIcon
								id={
									isCollection
										? item.collection_type.id
										: item.content_type.id
								}
							/>
							<S.Type>
								{isCollection
									? item.collection_type.name
									: item.content_type.name}
							</S.Type>
						</S.ContentTypeWrapper>
					</S.TopWrapper>
				)}
				<DialogTitle>
					<S.IconContainer>
						<Icon type="far fa-times" onClick={handleClose} />
					</S.IconContainer>
				</DialogTitle>
				<S.StyledDialogContent>
					<S.Cover
						className="cover"
						style={{
							backgroundImage: `url('${item.cover_id || noImg}')`
						}}
					/>
					{!isCollection ? (
						<ContentInfo item={item} />
					) : (
						<CollectionInfo
							// handleSelect={setSelectedItem}
							onCollectionContentClick={onCollectionContentClick}
							// selected={selectedItem}
							item={item}
						/>
					)}
				</S.StyledDialogContent>
				<S.StyledDialogActions>
					<S.FileLink>
						<S.GoToContentButton
							target="_blank"
							href={moreButtonLink(item)}
						>
							{t('content-detail.more', 'Ver Mais')}
						</S.GoToContentButton>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 3
							}}
						>
							<S.SharingButton
								onClick={() =>
									setIsSharingBarVisible(
										(prevState) => !prevState
									)
								}
							>
								<Icon
									type="far fa-regular fa-paper-plane"
									style={{
										color: theme.colors.primary,
										fontSize: '16px'
									}}
								/>
								{t('content-detail.share', 'Compartilhar')}
							</S.SharingButton>

							<ContentUserActions
								id={item.id}
								url={item.friendly_url}
								// handleSelect={setSelectedItem}
								item={item}
								onClose={handleClose}
								isCollection={isCollection}
							/>
						</Box>
					</S.FileLink>
				</S.StyledDialogActions>
				<S.SharingBar className={isSharingBarVisible && 'clicked'}>
					{children}
				</S.SharingBar>
			</Dialog>
		</>
	)
}

export default ContentOrCollectionDetailDialog
