import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { hasRoleOrPermission } from '../../../utils/functions'
import { menuList } from './menuList'

import * as S from './styles'
import { Divider, MenuItem } from '@mui/material'

const LeftMenu = ({ isWordpressMenu }) => {
	const location = useLocation()
	const pathname =
		location.pathname.substr(-1) === '/'
			? location.pathname.slice(0, -1)
			: location.pathname
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	let showMenu = menuList
	if (user) {
		showMenu = menuList.filter((item) => {
			const allow = hasRoleOrPermission(
				item.requiredRoles,
				user.roles,
				item.requiredPermission,
				user.permissions
			)
			if (allow) {
				return item
			}
		})
	}

	const getIcon = (item) => {
		const Icon = item.icon
		return (
			<S.IconContainer>
				<Icon />
			</S.IconContainer>
		)
	}

	const onMenuItemClick = () => {
		if (isWordpressMenu) {
			const closeMenuElements =
				document.getElementsByClassName('ct-toggle-close')
			if (closeMenuElements) {
				// @ts-ignore
				closeMenuElements[0].click()
			}
		}
	}

	return (
		<S.MenuWrapper is_wordpress_menu={isWordpressMenu.toString()}>
			{showMenu.map((item, idx) => {
				return [
					<MenuItem
						key={item.name}
						component={Link}
						to={item.href}
						onClick={onMenuItemClick}
						selected={item.href === pathname}
					>
						{getIcon(item)}
						<S.ItemTitle>{item.name}</S.ItemTitle>
					</MenuItem>,
					[0, 5, 7].includes(idx) && (
						<Divider
							style={{
								borderColor: 'rgba(0, 0, 0, 0.0)'
							}}
						/>
					)
				]
			})}
		</S.MenuWrapper>
	)
}

export default LeftMenu
