import { styled as muiStyled } from '@mui/material/styles'
import styled from '@emotion/styled'

import Button from '@mui/material/Button'
import { Box } from '@mui/material'

export const SideColumn = muiStyled(Box)`
  width: 220px;
	@media screen and (max-width: 782px) {
		display: none;
	}
`

export const Block = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 220px;
	height: 150px;
	border-radius: 8px;
	overflow: hidden;
	transition: box-shadow 0.3s ease-in-out;
	:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}
	:hover .content-type {
		opacity: 1;
	}
	.content {
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
	}
	img {
		display: flex;
		width: 220px;
		height: 126px;
		pointer-events: none;
		object-fit: cover;
	}
	@media screen and (max-width: 1024px) {
		height: 150px;
		img {
			height: 150px;
		}
	}
	@media screen and (max-width: 782px) {
		display: none;
	}
`
export const MobileBlock = styled(Block)`
	@media screen and (max-width: 782px) {
		display: flex !important;
		width: 100%;
		img {
			width: 100%;
		}
	}
	@media screen and (min-width: 782px) {
		display: none !important;
	}
`

export const Icon = styled.div`
	display: flex;
	padding: 4px 12px 4px 12px;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	background: var(--brown-lighten-5, #efebe9);
	i {
		font-size: 16px;
		color: #194d4894;
	}
`

export const Type = styled.p`
	color: #194d48;
	font-size: 12px;
	opacity: 0;
	transition: opacity 0.3s linear;
`

export const PreviewButton = muiStyled(Button)({
	marginTop: 12
})
