import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'semantic-ui-react'
import * as S from './styles'
import Button from '../../Button'

function SearchBox({
	handleChange,
	handleKeyDown,
	handleFilter,
	value,
	isHome,
	autoFocus = false
}) {
	const { t } = useTranslation()
	return (
		<S.InputContainer isHome={isHome}>
			<S.InputBackground>
				<S.Input
					id="filter"
					placeholder={t('search.searchbar-placeholder', 'Buscar')}
					name="title"
					autoFocus={autoFocus}
					onChange={handleChange}
					value={value}
					onKeyDown={handleKeyDown}
				/>
				<Button
					onClick={handleFilter}
					style={{
						borderRadius: 0,
						backgroundColor: 'rgb(213, 97, 21)',
						height: '54px',
						width: '52px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						border: 0,
						padding: 0
					}}
				>
					<Icon name="search" style={{ color: '#fff' }} />
				</Button>
			</S.InputBackground>
		</S.InputContainer>
	)
}

export default SearchBox
