import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

export const Panel = styled(Grid)`
	background-color: white;
`

export const AvatarInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h2 {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const EditInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	i {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const UserInfo = styled.div`
	width: 300px;
	> div {
		margin-bottom: 10px;
	}
	b {
		margin-left: 10px;
		font-weight: bold;
	}
	h1 {
		color: ${({ theme }) => theme.colors.secondary};
		margin-bottom: 0;
	}
`
