import { createTheme } from '@mui/material'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

const muiTheme = createTheme({
	typography: {
		fontSize: 22
	},
	palette: {
		primary: createColor('#194D48'),
		secondary: createColor('#D56115'),
		light: createColor('#dddddd')
	}
})
export default muiTheme
