import styled from 'styled-components'
import { List } from 'semantic-ui-react'

export const Item = styled(List.Item)`
	&&& {
		display: flex;
		align-items: flex-start;
		margin: 20px 0;
		color: #000;
		.content {
			margin: 0 10px;
		}
		@media screen and (max-width: 1280px) {
			flex-direction: column;
			align-items: center;
			.content {
				margin-top: 10px;
			}
		}
	}
`
