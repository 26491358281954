import styled from 'styled-components'

export const WrappPassword = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
export const EditInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	i {
		color: ${({ theme }) => theme.colors.primary};
	}
	form {
		width: 300px;
	}
`
