import * as S from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { userActions } from '../../../app/_actions'
import { ReactComponent as LoginIcon } from '../../../assets/icons/login.svg'
import { useTranslation } from 'react-i18next'

function LoginButton({ onClick: onSignInClick }) {
	const { t, i18n } = useTranslation()
	const { loggedIn } = useSelector((state) => state.authentication)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(userActions.hasToken())
	}, [dispatch])

	const goToPanel = () => {
		if (i18n.language == 'es-ES') {
			window.open('/panel/', '_self')
		} else {
			window.open('/painel/', '_self')
		}
	}

	return loggedIn ? (
		<S.LoginButton
			style={{ backgroundColor: 'rgba(255, 255, 255, 0.20)' }}
			onClick={goToPanel}
		>
			{t('login-button.panel', 'Painel')}
		</S.LoginButton>
	) : (
		<S.LoginButton onClick={onSignInClick}>
			<LoginIcon
				name="sign-in"
				style={{
					color: '#fff',
					fontSize: '16px'
				}}
			/>
			<S.LoginButtonText>Entrar</S.LoginButtonText>
		</S.LoginButton>
	)
}
export default LoginButton
