import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import FormatCellValue from '../../../../components/DataTable/FormatCellValue'
import ActionColumnTable from '../../../../components/DataTable/ActionColumnTable'
import CustomPagination from '../../../../components/Dashboard/CustomPagination'
import { searchActions } from '../../../../app/_actions'
import {
	getJustification,
	getStatusClassName
} from '../../../../utils/functions'
import EditCollectionWrapper from '../../../../components/Dashboard/EditCollection/EditCollectionWrapper'
import { collectionTypeSlugs } from '../../../../app/_constants'
import { Link } from '@mui/material'

const defaultSortModel = {
	field: 'created_at',
	sort: 'desc'
}

function ManageCollections({
	collectionTypeObject,
	searchValue,
	searchValueRef,
	isUserCollection,
	createNewCollection,
	setCreateNewCollection
}) {
	const [collectionModalOpen, setCollectionModalOpen] = useState(false)
	const [sortModel, setSortModel] = useState(defaultSortModel)
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
	})
	const { i18n } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const { collections } = useSelector(
		// @ts-ignore
		(state) => state.search
	)
	const { results, totalResults, loading } = collections
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const getData = useCallback(() => {
		if (user) {
			if (
				searchValue !== searchValueRef.current &&
				paginationModel.page > 0
			) {
				setPaginationModel({
					page: 0,
					pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
				})
				searchValueRef.current = searchValue
				return
			}
			const params = {
				q: searchValue,
				collection_type: collectionTypeObject.slug
			}
			if (isUserCollection) {
				params.user_id = user.id
			}
			dispatch(
				searchActions.getFilteredResults(
					params,
					paginationModel.page + 1,
					paginationModel.pageSize,
					i18n.language,
					sortModel?.field,
					sortModel?.sort
				)
			)
			searchValueRef.current = searchValue
		}
	}, [
		dispatch,
		paginationModel,
		sortModel?.field,
		sortModel?.sort,
		user,
		searchValue,
		isUserCollection,
		searchValueRef,
		collectionTypeObject.slug,
		i18n
	])

	const handleSortModelChange = useCallback((sort) => {
		if (sort[0] && sort[0].field && sort[0].sort) {
			setSortModel(sort[0])
		} else {
			setSortModel(defaultSortModel)
		}
	}, [])

	const handleEdit = (item) => {
		history.push(`/painel/editar/${item.friendly_url}`)
	}

	// const resetCollectionId = useCallback(() => {
	// 	setCollectionModalOpen(false)
	// }, [])

	let columns = [
		{
			field: 'actions',
			headerName: '',
			type: 'actions',
			getActions: (params) => [
				<ActionColumnTable
					key={params.row.index}
					item={params.row}
					onEdit={handleEdit}
					// checkStatus
				/>
			],
			flex: 0.4
		},
		{ field: 'title', headerName: 'Título', minWidth: 200 },
		{
			field: 'status',
			headerName: 'Status',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					status: true
				}),
			minWidth: 150
		},
		{
			field: 'reprove_justification',
			headerName: 'Justificativa',
			valueFormatter: (props) => {
				const row = props.api.getRow(props.id)
				return getJustification(row)
			},
			minWidth: 150
		},
		{
			field: 'cover_id',
			headerName: 'Imagem',
			renderCell: ({ value }) =>
				value ? (
					<Avatar
						src={value}
						alt="imagem"
						sx={{ width: 64, height: 64 }}
					/>
				) : (
					<>-</>
				)
		},
		{
			field: 'user',
			headerName: 'Usuária',
			flex: 0.5,
			minWidth: 150,
			valueGetter: (params) => params.row?.user?.name
		},
		{
			field: 'keywords',
			headerName: 'Palavras-chave',
			flex: 0.5,
			minWidth: 200
		},
		{
			field: 'date_start',
			headerName: 'Data de início',
			flex: 0.5,
			minWidth: 200,
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY'
				})
		},
		{
			field: 'date_end',
			headerName: 'Data de encerramento',
			flex: 0.5,
			minWidth: 200,
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY'
				})
		},
		{
			field: 'curso_type',
			headerName: 'Tipo de curso',
			flex: 0.5,
			minWidth: 200,
			valueFormatter: ({ value }) =>
				value && value.name ? value.name : ''
		},
		{
			field: 'curso_workload',
			headerName: 'Carga Horária',
			flex: 0.5,
			minWidth: 200
		},
		{
			field: 'projeto_financers',
			headerName: 'Financiador(es)',
			flex: 0.5,
			minWidth: 200
		},
		{
			field: 'projeto_url',
			headerName: 'Link do projeto',
			flex: 0.5,
			minWidth: 200,
			renderCell: ({ value }) => <Link href={value}>{value}</Link>
		},
		// {
		// 	field: 'organization',
		// 	headerName: 'Instituição',
		// 	flex: 0.5,
		// 	minWidth: 150,
		// 	valueGetter: (params) => params.row?.user?.organization?.name
		// },
		{
			field: 'created_at',
			headerClassName: 'super-app-theme--header',
			headerName: 'Criado em',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY HH:MM'
				}),
			flex: 0.5,
			minWidth: 150
		},
		{
			field: 'updated_at',
			headerName: 'Última atualização',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY HH:MM'
				}),
			flex: 0.5,
			minWidth: 150
		}
	]

	if (isUserCollection) {
		columns = columns.filter((col) => col.field !== 'user')
	}

	switch (collectionTypeObject.slug) {
		case collectionTypeSlugs.SERIE:
			columns = columns.filter(
				(col) =>
					![
						'date_start',
						'date_end',
						'projeto_financers',
						'projeto_url',
						'curso_type',
						'curso_workload',
						'curso_program'
					].includes(col.field)
			)
			break
		case collectionTypeSlugs.CURSO:
			columns = columns.filter(
				(col) =>
					!['projeto_financers', 'projeto_url'].includes(col.field)
			)
			break
		case collectionTypeSlugs.PROJETO:
			columns = columns.filter(
				(col) =>
					!['curso_type', 'curso_workload', 'curso_program'].includes(
						col.field
					)
			)
			break
	}

	useEffect(() => {
		getData()
	}, [getData])

	useEffect(() => {
		if (createNewCollection) {
			setTimeout(() => {
				setCreateNewCollection((currCreateNewCollection) => {
					if (currCreateNewCollection) {
						setCollectionModalOpen(true)
					}
					return false
				}, 500)
			})
		}
	}, [createNewCollection, setCreateNewCollection])

	return (
		<S.Panel /*celled="internally" stackable*/>
			<S.Table>
				<S.StyledDataGrid
					rows={results ? results : []}
					// @ts-ignore
					columns={columns}
					getRowHeight={() => 'auto'}
					getRowClassName={getStatusClassName}
					hideFooter={true}
					onPaginationModelChange={setPaginationModel}
					sortingMode="server"
					onSortModelChange={handleSortModelChange}
					disableRowSelectionOnClick={true}
					loading={loading}
				/>
			</S.Table>
			<S.PaginationContainer>
				<CustomPagination
					totalResults={totalResults}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
				/>
			</S.PaginationContainer>
			<EditCollectionWrapper
				collectionTypeObject={collectionTypeObject}
				collectionModalOpen={collectionModalOpen}
				setCollectionModalOpen={setCollectionModalOpen}
				onUpdate={getData}
			/>
		</S.Panel>
	)
}

export default ManageCollections
