import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'

export const Panel = styled(Grid)`
	background-color: white;
`

export const WrappInfo = styled.div`
	display: flex;
	> div {
		margin: 0px 40px;
	}
`

export const AvatarInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h2 {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const EditInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	i {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const UserInfo = styled.div`
	width: 300px;
	> div {
		margin-bottom: 10px;
	}
	b {
		margin-left: 10px;
		font-weight: bold;
	}
	h1 {
		color: ${({ theme }) => theme.colors.secondary};
		margin-bottom: 0;
	}
`

const dot = () => ({
	alignItems: 'center',
	display: 'flex',
	fontSize: '16px',
	padding: '6.5px 4px',
	margin: '0',
	borderColor: '#85b7d9'
})

const placeholder = () => ({
	fontFamily: 'Lato, Helvetica Neue',
	fontSize: '16px',
	padding: '5px',
	color: 'rgb(199 199 204)'
})

const single = () => ({
	fontFamily: 'Lato, Helvetica Neue',
	fontSize: '17px',
	padding: 9
})

export const colourStyles = {
	container: (styles) => ({ ...styles, width: '99%' }),
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...placeholder() }),
	singleValue: (styles) => ({ ...styles, ...single() }),
	option: (styles) => ({ ...styles, ...single() })
}
