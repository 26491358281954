import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import ContentToApproval from '../ContentToApproval'
import CollectionToApproval from '../CollectionToApproval'
import { collectionTypeList } from '../../../../app/_constants'

function CurationTable() {
	const [toggleCurators, setToggleCurators] = useState(true)
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const isCuratorChief = user.roles.includes('ROLE_CURADOR_CHEFE')
	const isCurator = user.roles.includes('ROLE_CURADOR')
	const isAdmin = user.roles.includes('ROLE_ADMIN')

	return (
		<>
			{(isAdmin || isCuratorChief) && (
				<>
					<Button
						secondary={toggleCurators}
						onClick={() => setToggleCurators(true)}
					>
						Mostrar conteúdos/coleções com curadores
					</Button>
					<Button
						secondary={!toggleCurators}
						onClick={() => setToggleCurators(false)}
					>
						Mostrar conteúdos/coleções sem curadores
					</Button>
				</>
			)}
			<>
				<h2>Conteúdos pendentes de aprovação</h2>
				<ContentToApproval
					contentCurators={toggleCurators}
					isAdmin={isAdmin}
					isCuratorChief={isCuratorChief}
					isCurator={isCurator}
				/>
				{collectionTypeList.map((collectionTypeObject) => (
					<React.Fragment key={collectionTypeObject.slug}>
						<h2>
							{collectionTypeObject.plural} pendentes de aprovação
						</h2>
						<CollectionToApproval
							contentCurators={toggleCurators}
							isAdmin={isAdmin}
							isCuratorChief={isCuratorChief}
							isCurator={isCurator}
							collectionTypeObject={collectionTypeObject}
						/>
					</React.Fragment>
				))}
			</>
		</>
	)
}

export default CurationTable
