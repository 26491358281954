import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'

import * as S from './styles'
import noImg from '../../../assets/no-image.jpg'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import ContentSheet from '../../PublicPages/ContentSheet'
import Alert from '@mui/material/Alert'
import {
	getJustification,
	getJustificationAlertType,
	getStatusAlertTitle
} from '../../../utils/functions'
import { STATUS } from '../../../constants'

// const host = process.env.REACT_APP_HOSTNAME

function ContentDetails({ item, isCurator = false }) {
	const justification = getJustification(item)
	const { title, cover_id, content_type, friendly_url, description, status } =
		item
	// const shareUrl = `${host}/conteudo/`

	const checkFilled = () => {
		const fieldsToBeChecked = [
			// STEP 1:
			'content_type_id',

			// STEP 2:
			'title',
			'description',
			'cover_id',

			// STEP 3:
			'author',
			'keywords',
			'how_cite',
			'organizations',
			'year',
			'language_ids',
			'pais_ids',
			'land_categories',
			'themes'
		]

		const emptyFields = fieldsToBeChecked.filter((key) => {
			if (!(key in item) || !item[key]) {
				return true
			}
			if (Array.isArray(item[key])) {
				return item[key].length === 0
			}
			return false
		})

		if (!item.content_link && !item.file_id) {
			emptyFields.push('attached_content')
		}

		if (item.pais_ids && item.pais_ids.includes(76)) {
			if (!item.estado_ids || item.estado_ids.length === 0) {
				emptyFields.push('estado_ids')
			}
			if (!item.bioma_ids || item.bioma_ids.length === 0) {
				emptyFields.push('bioma_ids')
			}
		}

		return emptyFields
	}

	const emptyFields = item ? checkFilled() : null

	const onPreviewClick = () => {
		const url = status == 1 ? '/conteudo/' : '/pre-visualizar/conteudo/'
		const win = window.open(url + friendly_url, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const AlertIcon = () => {
		switch (item.status) {
			case STATUS.REJEITADA_ANALISE:
			case STATUS.REJEITADO:
			case STATUS.EXCLUIDO:
				return <ErrorIcon fontSize="inherit" />
			case STATUS.APROVADO:
				return <CheckIcon fontSize="inherit" />
			case STATUS.AGUARDANDO_APROVACAO:
			default:
				return <WarningIcon fontSize="inherit" />
		}
	}

	return (
		<Box sx={{ padding: 4, display: 'flex', flexDirection: 'row', gap: 5 }}>
			<S.SideColumn>
				<S.Block className="img" onClick={onPreviewClick}>
					<img alt={title} src={cover_id || noImg} />
					{content_type && (
						<S.Icon>
							<ContentOrCollectionTypeIcon id={content_type.id} />
							<S.Type className="content-type">
								{content_type.name}
							</S.Type>
						</S.Icon>
					)}
				</S.Block>
				{![STATUS.REJEITADA_ANALISE, STATUS.REJEITADO].includes(
					item.status
				) && (
					<S.PreviewButton
						fullWidth
						onClick={onPreviewClick}
						variant="outlined"
						startIcon={<PreviewOutlinedIcon />}
					>
						Pré-Visualizar
					</S.PreviewButton>
				)}
			</S.SideColumn>
			<Box sx={{ flex: 1 }}>
				<Typography variant="h3">{title}</Typography>
				<Box sx={{ mt: 2 }}>{description}</Box>
				{![STATUS.RASCUNHO].includes(item.status) && (
					<Alert
						icon={AlertIcon()}
						severity={getJustificationAlertType(item)}
						sx={{ my: 2 }}
					>
						<Typography variant="h5">
							{getStatusAlertTitle(item)}
						</Typography>
						<Typography variant="body1" sx={{ mt: 2 }}>
							{justification
								? justification
								: [
										STATUS.SOLICITADA_CORRECAO,
										STATUS.REJEITADA_ANALISE,
										STATUS.REJEITADO
								  ].includes(item.status)
								? 'A/o curador(a) não deixou nenhuma justificativa'
								: item.status === STATUS.AGUARDANDO_APROVACAO
								? 'O conteúdo está sendo analisado.'
								: item.status === STATUS.APROVADO
								? 'O conteúdo foi aprovado após análise.'
								: ''}
						</Typography>
					</Alert>
				)}
				{[STATUS.AGUARDANDO_APROVACAO, STATUS.RASCUNHO].includes(
					item.status
				) && (
					<>
						{emptyFields && emptyFields.length === 0 && (
							<Alert
								icon={<CheckIcon fontSize="inherit" />}
								severity="success"
								sx={{ my: 2 }}
							>
								{isCurator
									? 'Todos os campos da ficha técnica estão preenchidos.'
									: 'Parabéns! Todos os campos da ficha técnica foram preenchidos.'}
							</Alert>
						)}
						{emptyFields && emptyFields.length > 0 && (
							<Alert
								icon={<WarningIcon fontSize="inherit" />}
								severity="warning"
								sx={{ my: 2 }}
							>
								{isCurator
									? `${emptyFields.length} campos da ficha técnica não foram preenchidos.`
									: `${emptyFields.length} campos da ficha técnica ficaram vazios.`}
								{[STATUS.RASCUNHO].includes(item.status) &&
									!isCurator &&
									' Preencha os campos para facilitar que seu conteúdo seja encontrado nos resultados de busca do portal.'}
							</Alert>
						)}
					</>
				)}
				<S.MobileBlock className="img" onClick={onPreviewClick}>
					<img alt={title} src={cover_id || noImg} />
					{content_type && (
						<S.Icon>
							<ContentOrCollectionTypeIcon id={content_type.id} />
							<S.Type className="content-type">
								{content_type.name}
							</S.Type>
						</S.Icon>
					)}
				</S.MobileBlock>
				<ContentSheet item={item} showEmptyFields={true} />
			</Box>
		</Box>
	)
}

export default ContentDetails
