import React, { useCallback, useRef, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Fab from '@mui/material/Fab'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Input } from 'semantic-ui-react'
import debounce from 'lodash.debounce'

import * as S from './styles'
import List from '../List'
import Icon from '../../../../components/Icon'
import CustomTabPanel from '../../../../components/Dashboard/CustomTabPanel'
import ManageCollections from '../ManageCollections'
import { collectionTypeList } from '../../../../app/_constants'
import { collectionsActions } from '../../../../app/_actions'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const ManageCollectionsWrapper = ({ isUserCollection = true }) => {
	const dispatch = useDispatch()
	const { collectionTypeParam } = useParams()
	const collectionTypeObject = collectionTypeList.find(
		(item) => item.plural.toLowerCase() === collectionTypeParam
	)
	const [createNewCollection, setCreateNewCollection] = useState(false)
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const [searchValue, setSearchValue] = useState('')
	const searchValueRef = useRef('')

	// eslint-disable-next-line
	const debounceCollection = useCallback(
		debounce((value) => {
			setSearchValue(value)
		}, 500),
		[]
	)

	const handleCollectionSearch = useCallback(
		({ target }) => {
			debounceCollection(target.value)
		},
		[debounceCollection]
	)

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	const handleAddCollection = () => {
		dispatch(collectionsActions.cleanCollection())
		setTabIndexValue(0)
		setCreateNewCollection(true)
	}

	if (!collectionTypeObject) {
		console.log('error finding this url')
		return <></>
	}

	const placeholder = `Pesquisar ${collectionTypeObject.name.toLowerCase()}...`

	return (
		<>
			<S.Wrapper>
				{isUserCollection ? (
					<>
						<S.Header>
							<S.Title>
								{collectionTypeObject.genre === 'a'
									? 'Minhas'
									: 'Meus'}{' '}
								{collectionTypeObject.plural}
							</S.Title>
							<Fab
								color="secondary"
								aria-label={`Adicionar ${collectionTypeObject.name}`}
								style={{ boxShadow: 'unset', zIndex: 1 }}
								onClick={handleAddCollection}
								size="small"
							>
								<Icon
									type={'fas fa-plus'}
									style={{
										fontSize: '18px'
									}}
								/>
							</Fab>
						</S.Header>
						<>
							<S.TabsContainer>
								<Tabs
									value={tabIndexValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
								>
									<Tab label="Gerenciar" {...a11yProps(0)} />
									<Tab
										label={`Ver Publicad${collectionTypeObject.genre}s`}
										{...a11yProps(1)}
									/>
								</Tabs>
								<Input
									icon="search"
									onChange={handleCollectionSearch}
									placeholder={placeholder}
								/>
							</S.TabsContainer>
							<CustomTabPanel value={tabIndexValue} index={0}>
								<ManageCollections
									collectionTypeObject={collectionTypeObject}
									searchValue={searchValue}
									searchValueRef={searchValueRef}
									isUserCollection={isUserCollection}
									createNewCollection={createNewCollection}
									setCreateNewCollection={
										setCreateNewCollection
									}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={tabIndexValue} index={1}>
								<List
									searchValue={searchValue}
									searchValueRef={searchValueRef}
								/>
							</CustomTabPanel>
						</>
					</>
				) : (
					<>
						<Input
							icon="search"
							onChange={handleCollectionSearch}
							placeholder={placeholder}
						/>
						<ManageCollections
							collectionTypeObject={collectionTypeObject}
							searchValue={searchValue}
							searchValueRef={searchValueRef}
							isUserCollection={isUserCollection}
							createNewCollection={createNewCollection}
							setCreateNewCollection={setCreateNewCollection}
						/>
					</>
				)}
			</S.Wrapper>
		</>
	)
}

export default withRouter(ManageCollectionsWrapper)
