// import { ReactComponent as ArtigoIcon } from './assets/icons/artigo.svg'
import React, { useEffect } from 'react'
import * as S from './styles'

import { ReactComponent as biodiversidade } from '../assets/themes/biodiversidade.svg'
import { ReactComponent as floresta } from '../assets/themes/floresta-e-agua.svg'
import { ReactComponent as pressoes } from '../assets/themes/pressoes-e-ameacas.svg'
import { ReactComponent as economia } from '../assets/themes/economia-verde.svg'
import { ReactComponent as povos } from '../assets/themes/povos-e-territorios.svg'
import { ReactComponent as gestao } from '../assets/themes/gestao.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { contentDataActions } from '../app/_actions'
import 'semantic-ui-css/semantic.min.css'

function ThemesGrid() {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	// @ts-ignore
	const { themes } = useSelector((state) => state.themes)

	useEffect(() => {
		dispatch(contentDataActions.getThemesWithTotals())
	}, [])

	const topics = [
		{
			label: t('search.themes.biodiversity.title', 'Biodiversidade'),
			icon: biodiversidade,
			id: 1,
			description: t(
				'search.themes.biodiversity.description',
				'Diversidade biológica, recursos naturais, iniciativas de conservação, monitoramento e outros assuntos relacionados à natureza.'
			)
		},
		{
			label: t('search.themes.forest-water.title', 'Floresta e água'),
			icon: floresta,
			id: 2,
			description: t(
				'search.themes.forest-water.description',
				'Manejo de florestas, recursos pesqueiros, serviços ecossistêmicos, água, restauração florestal, e outros temas ligados ao uso e proteção dos recursos hídricos e florestais.'
			)
		},
		{
			label: t('search.themes.threats.title', 'Pressões e ameaças'),
			icon: pressoes,
			id: 3,
			description: t(
				'search.themes.threats.description',
				'Desmatamento, fogo, mudanças climáticas, exploração de madeira, pesca ilegal e garimpo são algumas das pressões e ameaças que põem em risco as áreas protegidas.'
			)
		},
		{
			label: t('search.themes.green-economy.title', 'Economia verde'),
			icon: economia,
			id: 4,
			description: t(
				'search.themes.green-economy.description',
				'Cadeias da sociobiodiversidade, desenvolvimento sustentável, turismo e inovação para gerar valor.'
			)
		},
		{
			label: t(
				'search.themes.people-territory.title',
				'Povos e territórios'
			),
			icon: povos,
			id: 5,
			description: t(
				'search.themes.people-territory.description',
				'Modos de vida, cultura e saberes de diversos povos e comunidades indígenas e tradicionais que contribuem para a preservação das áreas protegidas.'
			)
		},
		{
			label: t('search.themes.management.title', 'Gestão'),
			icon: gestao,
			id: 6,
			description: t(
				'search.themes.management.description',
				'A manutenção e a criação de áreas protegidas dependem de uma gestão jurídico-administrativa, ambiental e territorial eficiente.'
			)
		}
	]
	const ThemeIcon = ({ themeId, size, color }) => {
		const Icon = topics.find((topic) => topic.id === themeId).icon
		const sizePx = `${size}px`
		return (
			<Icon
				size={size}
				fill={color}
				style={{
					windowidth: sizePx,
					minWidth: sizePx,
					height: sizePx,
					minHeight: sizePx
				}}
			/>
		)
	}

	// function handleClick(e) {
	// 	e.preventDefault()
	// 	setIsFlipped((prevState) => !prevState)
	// }

	return (
		<S.Wrapper>
			{themes.map((theme, idx) => {
				return (
					<S.FlipCard key={idx}>
						<S.FlipCardInner>
							<S.ThemeFront>
								<ThemeIcon
									themeId={theme.id}
									size={110}
									color="#fff"
								/>
								<S.FrontTile>
									{
										topics.find(
											(topic) => topic.id == theme.id
										).label
									}
								</S.FrontTile>
								<S.FrontDescription>
									{
										topics.find(
											(topic) => topic.id == theme.id
										).description
									}
								</S.FrontDescription>
							</S.ThemeFront>
							<S.ThemeBack>
								<S.Totals>
									{theme.total_contents}{' '}
									{t('search.themes.content', 'conteúdos')}
								</S.Totals>
								<S.Button
									href={
										i18n.language == 'es-ES'
											? 'resultado-busqueda/?theme_id[]=' +
											  theme.id
											: 'resultado-busca/?theme_id[]=' +
											  theme.id
									}
								>
									{t('search.themes.search', 'Buscar')}
								</S.Button>
							</S.ThemeBack>
						</S.FlipCardInner>
					</S.FlipCard>
				)
			})}
		</S.Wrapper>
	)
}

export default ThemesGrid
