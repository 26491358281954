import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../../../components/Dashboard/PrivateRoute'

import ManageAll from '../../../components/Dashboard/ManageAll'
import ManageUsers from '../../../components/Dashboard/ManageUsers'
import ManageOrgs from '../../../components/Dashboard/ManageOrgs'

import * as S from './styles'
import AllContent from '../Content/AllContent'

const Management = () => {
	return (
		<S.Panel stackable celled="internally">
			<Switch>
				<PrivateRoute
					exact
					path={'/painel/administração/visão-geral'}
					component={ManageAll}
				/>
				<PrivateRoute
					exact
					path={'/painel/administração/usuárias'}
					component={ManageUsers}
				/>
				<PrivateRoute
					exact
					path={'/painel/administração/organizações'}
					component={ManageOrgs}
				/>
				<PrivateRoute
					exact
					path={'/painel/administração/conteúdos'}
					component={AllContent}
				/>
			</Switch>
		</S.Panel>
	)
}

export default Management
