import { metricsConstants } from '../_constants'
import { metricsService } from '../_services'
// import { alertActions } from './alert.actions'

export const metricsActions = {
	getPainelMetrics
}

function getPainelMetrics() {
	return (dispatch) => {
		dispatch(request())
		metricsService.getPainelMetrics().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: metricsConstants.REQUEST }
	}
	function success(data) {
		return { type: metricsConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: metricsConstants.FAILURE, error }
	}
}
