import React from 'react'
import LoginButton from './components/LoginButton'
import { useState } from 'react'
import { Provider } from 'react-redux'
import { Dialog, Tab, Tabs } from '@mui/material'
import * as S from './styles/global'
import { useTranslation } from 'react-i18next'
import store from '../app/store'
import 'semantic-ui-css/semantic.min.css'

import SignIn from './components/SignIn'
import SignUp from './components/SignUp'

function Login() {
	const [showLogin, setShowLogin] = useState(false)
	const [tabIndex, setTabIndexValue] = useState(0)
	const [registerSuccess, setRegisterSuccess] = useState(false)
	const { t } = useTranslation()

	const handleTabChange = (event, newValue) => {
		setTabIndexValue(newValue)
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		}
	}

	function CustomTabPanel(props) {
		const { children, value, index, ...other } = props
		return (
			<div
				role="tabpanel"
				key={`simple-tabpanel-${index}`}
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && children}
			</div>
		)
	}

	function dismissLogin() {
		setShowLogin(false)
		setRegisterSuccess(false)
	}

	return (
		<>
			<Provider store={store}>
				<LoginButton onClick={() => setShowLogin(true)} />
				<>
					<Dialog
						open={showLogin}
						onClose={dismissLogin}
						maxWidth="sm"
						fullWidth
					>
						<S.Wrapper
							onClick={(e) => {
								e.stopPropagation()
							}}
						>
							{!registerSuccess && (
								<>
									<S.TabsContainer>
										<Tabs
											value={tabIndex}
											onChange={handleTabChange}
											sx={{ color: '#000' }}
											indicatorColor="secondary"
										>
											<Tab
												label="Entrar"
												{...a11yProps(0)}
											/>
											<Tab
												label="Cadastrar"
												{...a11yProps(1)}
											/>
										</Tabs>
									</S.TabsContainer>
									<CustomTabPanel
										key={'tab0'}
										value={tabIndex}
										index={0}
									>
										<SignIn
											goToSignUp={() =>
												setTabIndexValue(1)
											}
										/>
									</CustomTabPanel>
									<CustomTabPanel
										key={'tab1'}
										value={tabIndex}
										index={1}
									>
										<SignUp
											setRegisterSuccess={
												setRegisterSuccess
											}
										/>
									</CustomTabPanel>
								</>
							)}
							{registerSuccess && (
								<S.EmailSent>
									<S.StyledHeader>
										{t('login.enter', 'Pronto!')}
									</S.StyledHeader>
									{t(
										'login.verify-your-email',
										'Verifique o seu e-mail para confirmação do cadastro.'
									)}
								</S.EmailSent>
							)}
						</S.Wrapper>
					</Dialog>
				</>
			</Provider>
		</>
	)
}

export default Login
