import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import Icon from '../../Icon'

export const Panel = styled(Grid)`
	background-color: white;
`

export const StyledCenteredColumn = styled(Grid.Column)`
	&&& {
		text-align: center;
		display: flex !important;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
`

export const StyledIcon = styled(Icon)`
	font-size: 100px;
	padding: 50px;
`

export const Description = styled.p`
	font-size: 14px;
`

export const WrappItem = styled.div`
	display: grid;
	grid-template-columns: repeat(3, auto);
	:not(:last-child) {
		border-bottom: 1px solid #f1f1f1;
	}
	div:first-child {
		min-width: 140px;
	}
	@media screen and (max-width: 782px) {
		display: flex;
		flex-wrap: wrap;
	}
`

export const ItemDesc = styled.div`
	display: flex;
	align-items: baseline;
	margin: 5px 0;
	i {
		margin-right: 10px;
		font-size: 30px;
		width: 40px;
	}
	h2 {
		margin-top: 0;
	}
`

export const WrappComments = styled.div`
	display: flex;
	align-items: center;
	i {
		padding: 20px;
		background: rgba(51, 51, 51, 0.1);
		border-radius: 50%;
		margin-right: 20px;
	}
`

export const CommentInfo = styled.span`
	font-size: 12px;
	color: rgba(51, 51, 51, 0.7);
`

export const Comment = styled.p`
	font-size: 14px;
`
