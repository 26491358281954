import React, { useState } from 'react'
import { Form, Label, Loader, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import * as S from './styles'
import * as SGlobal from '../../styles/global'
import { useTranslation } from 'react-i18next'
import useScript from '../utils/useScript'
import { alertActions, userActions } from '../../../app/_actions'

const SignIn = ({ goToSignUp }) => {
	const { t } = useTranslation()

	const fields = [
		{
			type: 'email',
			name: 'email',
			label: t('login.email', 'E-mail'),
			placeholder: 'exemplo@exemplo.com.br',
			required: true
		},
		{
			type: 'password',
			name: 'password',
			label: t('login.password', 'Senha'),
			placeholder: 'Digite sua senha',
			required: true
		}
	]
	// import g-captcha
	useScript('https://www.google.com/recaptcha/api.js')

	const [inputs, setInputs] = useState({
		email: '',
		password: ''
	})
	const [submitted, setSubmitted] = useState(false)
	const [forgotPasswordSubmitted, setForgotPasswordSubmitted] =
		useState(false)
	const { email, password } = inputs
	const { loggingIn, fetchingForgot } = useSelector(
		(state) => state.authentication
	)
	const { type, message, component } = useSelector((state) => state.alert)
	const dispatch = useDispatch()

	function handleChange(e) {
		const { name, value } = e.target
		setInputs((inputs) => ({ ...inputs, [name]: value }))
		if (forgotPasswordSubmitted) setForgotPasswordSubmitted(false)
	}

	function handleSubmit(e) {
		e.preventDefault()
		dispatch(alertActions.clear())
		const token = window.grecaptcha.getResponse()

		if (!token) {
			dispatch(
				alertActions.error(
					t(
						'error.login.fill-captcha-correctly',
						'Preencha o captcha corretamente!'
					),
					'signin'
				)
			)
		}
		setSubmitted(true)
		if (forgotPasswordSubmitted) setForgotPasswordSubmitted(false)
		if (inputs.email && inputs.password && token) {
			dispatch(userActions.login(email, password, token))
			window.grecaptcha.reset()
		}
	}

	function handleForgotPassword(e) {
		e.preventDefault()

		dispatch(alertActions.clear())
		setForgotPasswordSubmitted(true)
		if (inputs.email) {
			// get return url from location state or default to home page
			dispatch(userActions.getForgotPassword(email))
		}
	}

	return (
		<SGlobal.ModalWrapper>
			{type && message && component === 'signin' && (
				<SGlobal.AlertMessage
					className={loggingIn || fetchingForgot ? '' : 'shake'}
					color={type}
					header={message}
				/>
			)}
			<SGlobal.StyledHeader>
				{t('login.enter', 'Entrar')}
			</SGlobal.StyledHeader>
			<Form size="large" onSubmit={handleSubmit}>
				<SGlobal.InputWrapper>
					{fields.map((field) => {
						return (
							<React.Fragment key={`${field.name}_key`}>
								<Form.Input
									type={field.type}
									label={
										<>
											{field.label}
											{field.required && '*'}
										</>
									}
									error={
										(forgotPasswordSubmitted &&
											field.type === 'email' &&
											!inputs['email']) ||
										(submitted &&
											field.required &&
											!inputs[field.name])
									}
									name={field.name}
									placeholder={field.placeholder}
									onChange={handleChange}
									autoComplete="on"
									size="massive"
									iconPosition="left"
								/>
								{forgotPasswordSubmitted &&
									field.type === 'email' &&
									!inputs['email'] && (
										<Label basic color="red" pointing>
											{t(
												'login.email-recover-password',
												'Preencha o e-mail para recuperar sua senha.'
											)}
										</Label>
									)}
							</React.Fragment>
						)
					})}
					<SGlobal.LoginButton loading={loggingIn}>
						<Icon name="sign-in" />
						{t('login.login', 'Entrar')}
					</SGlobal.LoginButton>
					<S.Recaptcha
						className="g-recaptcha"
						data-sitekey={process.env.REACT_APP_CAPTCHA_KEY}
					></S.Recaptcha>
				</SGlobal.InputWrapper>
			</Form>
			<S.ForgotPassword>
				{fetchingForgot ? (
					<Loader active indeterminate inline>
						{t('login.checking-info', 'Verificando informações')}
					</Loader>
				) : (
					<a
						style={{ cursor: 'pointer' }}
						onClick={handleForgotPassword}
					>
						{t('login.recover-password', 'Esqueci minha senha')}
					</a>
				)}
			</S.ForgotPassword>
			<S.CreateAccount>
				{t('login.dont-have-account', 'Ainda não tem uma conta?')}{' '}
				<a style={{ cursor: 'pointer' }} onClick={goToSignUp}>
					{t('login.go-to-signup', 'Crie uma')}
				</a>
			</S.CreateAccount>
		</SGlobal.ModalWrapper>
	)
}

export default SignIn
