import styled from 'styled-components'

export const SearchBoxWrapp = styled.ol`
	${({ isWpLoggedIn }) =>
		isWpLoggedIn
			? `
    top: 54px;
`
			: `
    top: 24px;
`}
	display: flex;
	height: 0;
	flex-direction: column;
	justify-content: start;
	padding-left: 230px;
	position: fixed;
	z-index: 9999999;
	width: 100vw;
	@media screen and (max-width: 1050px) {
		height: 80px;
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
      top: 132px;
`
				: `
      top: 100px;
`}
		padding-left: 30px;
		background: ${({ theme }) => theme.colors.primary};
	}
	@media screen and (max-width: 782px) {
		${({ isWpLoggedIn }) =>
			isWpLoggedIn
				? `
      top: 145px;
`
				: `
      top: 100px;
`}
		padding-left: 16px;
	}
	@media screen and (max-width: 600px) {
		top: 100px;
	}
`

export const Wraper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 230px;
	padding-top: 52px;
	@media screen and (max-width: 1200px) {
		padding-top: 80px;
		margin: 0 16px;
	}
	@media screen and (max-width: 1050px) {
		align-items: center;
	}
`

export const ContentWraper = styled.div`
	display: flex;
	width: 100%;
	padding-top: 52px;
	justify-content: flex-start;
	flex-direction: row;
	align-items: flex-start;
	background: #fff;
	gap: 35px;
	@media screen and (max-width: 1050px) {
		flex-direction: column-reverse;
		align-items: center;
	}
`

export const Main = styled.div`
	display: flex;
	max-width: 806px;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export const SideInfo = styled.div`
	display: flex;
	width: 250px;
	padding: 16px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
`
export const UserActions = styled.div`
	display: flex;
	gap: 8px;
`

export const SharingButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	color: ${({ theme }) => theme.colors.primary};
	gap: 10px;
	border-radius: 100px;
	background: rgba(0, 0, 0, 0.05);
	:hover {
		cursor: pointer;
	}
`

export const SharingBar = styled.div`
	position: relative;
	width: 100%;
	margin-top: -95px;
	transition: margin 0.5s;
	&.clicked {
		margin-top: 0px;
	}
`
export const UserActionsContainer = styled.div`
	display: flex;
	z-index: 9;
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 19px 0px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	@media screen and (max-width: 782px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`

export const ContentButtonsContainer = styled.div`
	gap: 8px;
	display: flex;
`

export const Themes = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`
export const ThemeChip = styled.a`
	display: flex;
	padding: 2px 12px;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	border: 1px solid #d7ccc8;
	background-color: #fff;
	color: #194d48;
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	transition: all 0.3s ease;
	:hover {
		background-color: #194d48;
		color: #fff;
	}
`
export const Title = styled.p`
	width: 100%;
	font-size: 34px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.primary};
	margin: 0;
	padding-top: 0 !important;
	@media screen and (max-width: 782px) {
		font-size: 24px;
	}
`
export const Info = styled.div`
	display: flex;
	width: 100%;
	gap: 4px;
`

export const InfoTitle = styled.span`
	width: 100%;
	color: #424242;
	font-weight: 700;
	font-size: 15px;
`

export const InfoText = styled.span`
	color: #424242;
	font-size: 15px;
	font-weight: 400;
`

export const InfoWrapper = styled.span`
	width: 100%;
`

export const Short = styled.p`
	font-size: 14px;
	width: 100%;
	color: ${({ theme }) => theme.colors.dark};
`
export const Block = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 220px;
	height: 150px;
	border-radius: 8px;
	overflow: hidden;
	transition: box-shadow 0.3s ease-in-out;
	:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
	}
	:hover .content-type {
		opacity: 1;
	}
	.content {
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
	}
	img {
		display: flex;
		width: 220px;
		height: 126px;
		pointer-events: none;
		object-fit: cover;
	}
	@media screen and (max-width: 1024px) {
		height: 150px;
		img {
			height: 150px;
		}
	}
`
export const Icon = styled.div`
	display: flex;
	padding: 4px 12px 4px 12px;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	background: var(--brown-lighten-5, #efebe9);
	i {
		font-size: 16px;
		color: #194d4894;
	}
`
export const Type = styled.p`
	color: #194d48;
	font-size: 12px;
	opacity: 0;
	transition: opacity 0.3s linear;
`

export const FileLink = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	padding: 16px 16px;
	gap: 8px;
	.user-actions {
		display: flex;
		gap: 8px;
	}
	.user-actions > div,
	.download-button > a {
		width: 100%;
		justify-content: center;
	}
`

export const DownloadButton = styled.a`
	height: 36px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	border-radius: 4px;
	background: #d56115;
	color: #fff;
	padding: 0px 16px;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	:hover {
		color: #fff;
	}
`

export const LinkButton = styled.a`
	height: 36px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	border-radius: 4px;
	color: #d56115;
	border: 1px solid #d56115;
	background: #fff;
	padding: 0px 16px;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	:hover {
		color: #d56115;
	}
`

export const TabsContainer = styled.div`
	.MuiTabs-indicator {
		background: #d56115;
	}
	.MuiButtonBase-root {
		font-size: 15px;
	}
	.MuiButtonBase-root[aria-selected='true'] {
		color: #194d48;
	}
`
