import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Image, List, Pagination } from 'semantic-ui-react'

import { contentActions } from '../../../app/_actions'
import noImg from '../../../assets/no-image.jpg'

import * as S from './styles'

const PER_PAGE = 5
function MyFavorites({ title = 'Meus favoritos', size = 'medium', showMore }) {
	const [page, setPage] = useState(1)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(contentActions.getUserFavorites(page, PER_PAGE))
	}, [dispatch, page])

	// @ts-ignore
	const { favorite } = useSelector((state) => state.content)

	return (
		<div>
			<h1>{title}</h1>
			{favorite && favorite.content ? (
				<List divided>
					{favorite.content.map((item) => {
						return (
							<S.Item
								key={item.id}
								target="blank"
								href={`/conteudo/${item.friendly_url}`}
							>
								<Image
									// @ts-ignore
									size={size}
									src={item.cover_id || noImg}
									style={{ objectFit: 'contain' }}
								/>
								<List.Content>
									<List.Header as="h3">
										{item.title}
									</List.Header>
									<List.Description>
										{item.description}
									</List.Description>
								</List.Content>
							</S.Item>
						)
					})}
					{showMore ? (
						<NavLink to="/painel/minha-conta/favoritos">
							<Button>Ver todos os favoritos</Button>
						</NavLink>
					) : (
						<Pagination
							defaultActivePage={page}
							totalPages={Math.ceil(
								favorite.totalResults / PER_PAGE
							)}
							onPageChange={(e, { activePage }) => {
								e.preventDefault()
								// @ts-ignore
								setPage(activePage)
							}}
						/>
					)}
				</List>
			) : (
				<h3>
					Você ainda não favoritou nenhum dos conteúdos disponíveis.
				</h3>
			)}
		</div>
	)
}

export default MyFavorites
